import React, { useCallback } from 'react';
import {
	Flex,
	HStack,
	Tooltip,
	Center,
	Text,
	StackProps,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../Icon/Icon';

interface NavItemProps {
	iconName: string;
	children: React.ReactNode;
	link: string;
	menuExpanded: boolean;
	isAccordionItem?: boolean;
	horizontalStackProps?: StackProps;
}
export const NavItem: React.FC<NavItemProps> = ({
	iconName,
	children,
	link,
	menuExpanded,
	isAccordionItem = false,
	horizontalStackProps,
}) => {
	const getNavBgColor = useCallback(
		(isActive: boolean) => {
			if (!isActive) {
				return undefined;
			}
			if (!isAccordionItem) {
				return 'blueText';
			}
			return 'lightBlue';
		},
		[menuExpanded, isAccordionItem],
	);

	return (
		<Flex w="100%" justify={menuExpanded ? 'flex-start' : 'center'}>
			<NavLink
				style={{
					width: `${menuExpanded ? 'inherit' : 'auto'}`,
				}}
				to={link}>
				{({ isActive }) => (
					<HStack
						transition=".3s"
						borderRadius={8}
						color="white"
						spacing="10px"
						bg={getNavBgColor(isActive)}
						_hover={{
							bg: isAccordionItem ? 'lightBlue' : 'blueText',
							color: 'white',
						}}
						px={menuExpanded ? '10px' : '16px'}
						py="16px"
						{...horizontalStackProps}>
						<Tooltip
							fontSize="md"
							label={!menuExpanded ? children : ' '}
							placement="bottom"
							shouldWrapChildren={true}>
							<Center minW="24px">
								{iconName ? (
									<Icon
										name={iconName}
										width={
											isAccordionItem ? '20px' : '24px'
										}
										height={
											isAccordionItem ? '20px' : '24px'
										}
									/>
								) : null}
							</Center>
						</Tooltip>
						{menuExpanded ? (
							<Text
								fontWeight={isAccordionItem ? '500' : '600'}
								fontSize={isAccordionItem ? '14px' : '16px'}
								lineHeight={isAccordionItem ? '17px' : '19px'}
								whiteSpace="nowrap">
								{children}
							</Text>
						) : null}
					</HStack>
				)}
			</NavLink>
		</Flex>
	);
};
