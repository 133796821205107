import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { SideBar } from './components';
import { Outlet } from 'react-router-dom';

export const DashboardLayout: React.FC = () => {
	const [menuExpanded, setMenuExpanded] = useState(true);

	return (
		<Box minH="100vh" background={'background'}>
			<SideBar
				menuExpanded={menuExpanded}
				setMenuExpanded={setMenuExpanded}
			/>
			<Box
				ml={{ base: 0, md: menuExpanded ? '250px' : '120px' }}
				transition=".3s"
				p={{ base: '24px', md: '16px 40px' }}>
				<Outlet />
			</Box>
		</Box>
	);
};
