import React, { useState, Dispatch, SetStateAction } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
} from 'react-query';
import { IUser, GuestUser } from '../../types';
import { deleteUsersRequest } from '../../helper';

interface IDeleteUsersModalProps {
	isOpen: boolean;
	onClose: () => void;
	usersDataForDeletion: (IUser | GuestUser)[];
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
	) => Promise<QueryObserverResult<any, unknown>>;
	userIdsForDeletion: number[];
	setUserIdsForDeletion: Dispatch<SetStateAction<number[]>>;
	setUsersDataForDeletion: Dispatch<SetStateAction<(IUser | GuestUser)[]>>;
}

export const DeleteUsersModal: React.FC<IDeleteUsersModalProps> = ({
	isOpen,
	onClose,
	usersDataForDeletion,
	userIdsForDeletion,
	refetch,
	setUsersDataForDeletion,
	setUserIdsForDeletion,
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const onSuccessUserDeletion = () => {
		onClose();
		refetch();
		setUsersDataForDeletion([]);
		setUserIdsForDeletion([]);
	};

	const handleUsersDeletion = async () => {
		setIsLoading(true);
		await deleteUsersRequest(userIdsForDeletion, onSuccessUserDeletion);

		setIsLoading(false);
	};
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Delete users</ModalHeader>
				<ModalCloseButton />
				<ModalBody
					maxHeight={'322px'}
					maxW={'488px'}
					overflowY={'auto'}
					overflowX={'auto'}>
					<TableContainer>
						<Table variant="simple">
							<Thead>
								<Tr>
									<Th px={'3px'}>First name</Th>
									<Th px={'3px'}>Last name</Th>
									<Th px={'3px'}>Email</Th>
								</Tr>
							</Thead>
							<Tbody>
								{usersDataForDeletion?.map((item, index) => (
									<Tr key={index}>
										<Td px={'3px'}>{item.firstName}</Td>
										<Td px={'3px'}>{item.lastName}</Td>
										<Td px={'3px'}>{item.email}</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</ModalBody>

				<ModalFooter>
					<Button colorScheme="blue" mr={3} onClick={onClose}>
						Cancel
					</Button>
					<Button
						isLoading={isLoading}
						loadingText="Submitting"
						onClick={handleUsersDeletion}
						variant="outline"
						type="submit">
						Submit
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
