import React from 'react';
import {
	Box,
	Flex,
	BoxProps,
	CloseButton,
	Text,
	IconButton,
	Center,
	Spinner,
	Avatar,
	VStack,
	HStack,
} from '@chakra-ui/react';
import { LinkItems } from '../../../constants';
import { NavItem } from './NavItem';
import { useAuth } from 'oidc-react';
import { LogoutIcon } from '../../../assets/icons';
import { ExpandedButton } from './ExpandedButton';
import { ICurrentUser } from '../../../types';
import { NavAccordion } from './NavAccordion';
import { Icon } from '../../Icon/Icon';

interface SidebarProps extends BoxProps {
	onClose: () => void;
	menuExpanded: boolean;
	setMenuExpanded: (value: boolean) => void;
	data?: ICurrentUser;
	isLoading: boolean;
}

export const SidebarContent: React.FC<SidebarProps> = ({
	onClose,
	menuExpanded,
	setMenuExpanded,
	data,
	isLoading,
	...rest
}: SidebarProps) => {
	const { signOut } = useAuth();

	const fullName = `${data?.firstName} ${data?.lastName}`;

	return (
		<Flex
			direction="column"
			align="space-between"
			bg="lightBlue"
			transition=".3s"
			w={{ base: 'full', md: menuExpanded ? '250px' : '120px' }}
			h="100vh"
			pos="fixed"
			{...rest}>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				px={menuExpanded ? '23px' : '19px'}
				py={4}
				boxShadow="0px 4px 4px -4px #0F1B4999">
				<Box
					w={menuExpanded ? '204px' : '82px'}
					minW={menuExpanded ? '204px' : '82px'}
					overflow="clip"
					transition="width .2s, min-width .2s"
					h="32px">
					{menuExpanded ? (
						<Icon
							name="logo-admin"
							width="204px"
							height="32px"
							color="transparent"
						/>
					) : (
						<Icon
							name="logo"
							width="82px"
							height="32px"
							color="transparent"
						/>
					)}
				</Box>
				<CloseButton
					color="white"
					display={{ base: 'flex', md: 'none' }}
					onClick={onClose}
				/>
			</Flex>
			<VStack
				flexGrow={1}
				p="10px"
				spacing={1}
				overflowX="clip"
				overflowY="auto"
				className="vertical-scroll-main">
				{LinkItems.map(item => {
					if (item.text === 'Community') {
						return (
							<NavAccordion
								key={item.id}
								menuExpanded={menuExpanded}>
								{item.innerLinks?.map(link => {
									return (
										<NavItem
											key={link.text}
											iconName={link.iconName}
											link={link.link}
											isAccordionItem={true}
											menuExpanded={menuExpanded}
											horizontalStackProps={{
												p: menuExpanded
													? '10px'
													: '10px 8px',
											}}>
											Verification
										</NavItem>
									);
								})}
							</NavAccordion>
						);
					}
					return (
						<NavItem
							key={item.text}
							iconName={item.iconName}
							link={item.link}
							menuExpanded={menuExpanded}>
							{item.text}
						</NavItem>
					);
				})}
			</VStack>

			<Box
				boxShadow="0px -4px 4px -4px #0F1B4999"
				p="10px"
				maxW="250px"
				overflowX="clip">
				{isLoading ? (
					<Center>
						<Spinner size="lg" color="white" my={2} />
					</Center>
				) : (
					<HStack
						spacing={1}
						w={menuExpanded ? 'auto' : 'fit-content'}
						mx="auto"
						transition="width .4s">
						<HStack spacing="10px" py="4px">
							<Avatar name={fullName} boxSize={'40px'} />
							{menuExpanded ? (
								<VStack
									w="auto"
									align="flex-start"
									flex={1}
									spacing={0}
									transition=".4s"
									isTruncated={true}>
									<Text
										title={fullName}
										color="dropBlue"
										fontSize="14px"
										lineHeight="21px"
										fontWeight={600}
										maxW="135px"
										isTruncated={true}
										transition="width .4s">
										{fullName}
									</Text>
									<Text
										title={data?.email}
										color="dropBlue"
										fontSize="12px"
										lineHeight="18px"
										maxW="135px"
										isTruncated={true}
										transition="width .4s">
										{data?.email}
									</Text>
								</VStack>
							) : null}
						</HStack>

						<IconButton
							ml={'auto'}
							onClick={async () => {
								await signOut();
							}}
							background={'none'}
							borderRadius="8px"
							_hover={{
								background: 'blueText',
							}}
							_active={{
								background: 'blueText',
							}}
							aria-label={'sign-out'}
							icon={<LogoutIcon />}
						/>
					</HStack>
				)}
			</Box>
			<ExpandedButton
				menuExpanded={menuExpanded}
				onClick={() => setMenuExpanded(!menuExpanded)}
			/>
		</Flex>
	);
};
