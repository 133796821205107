import React, { useEffect } from 'react';
import { Box, Flex, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { steps } from '../constants';
import { Stepper } from '../components';
import { useSteps } from '../../../hooks';
import {
	FirstStepTab,
	SecondStepTab,
	FourthStepTab,
	ThirdStepTab,
} from '../tabs';
import { useCourseContext } from '../context';

export const CreateCourse: React.FC = () => {
	const location = useLocation();

	const courseId = location.state?.courseId as number;

	const { goToNext, goToPrevious, activeStep, setActiveStep } = useSteps({
		index: 0,
	});

	const { setCourseId } = useCourseContext();

	useEffect(() => {
		if (courseId) {
			setCourseId(courseId);
		}
	}, [courseId]);

	return (
		<Box>
			<Tabs isLazy={true} isManual={true} index={activeStep}>
				<Flex>
					{steps.map((it, index) => {
						const isCompleted = index < activeStep;
						const isCurrent = index === activeStep;
						const isActive = isCompleted || isCurrent;
						return (
							<Stepper
								key={index}
								isActive={isActive}
								label={it.label}
								stepNumber={index + 1}
							/>
						);
					})}
				</Flex>
				<TabPanels>
					<TabPanel px={0}>
						<FirstStepTab goToNext={goToNext} />
					</TabPanel>
					<TabPanel px={0}>
						<SecondStepTab setActiveStep={setActiveStep} />
					</TabPanel>
					<TabPanel px={0}>
						<ThirdStepTab
							setActiveStep={setActiveStep}
							goToNext={goToNext}
							goToPrevious={goToPrevious}
						/>
					</TabPanel>
					<TabPanel px={0}>
						<FourthStepTab setActiveStep={setActiveStep} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
