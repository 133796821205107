import { generateTimeOptions } from '../../utils';
import { RiskScoreFactor } from './types';

export const labelTextStyles = {
	fontSize: '18px',
	color: 'lightText',
};

export const textStyles = {
	fontSize: '18px',
	color: 'darkBlue',
	fontWeight: '700',
};

export enum TimelineTypeEnum {
	Call = 1,
	Email,
	DirectMessage,
	Note,
}

export const timelineTypeOptions = [
	{
		label: 'Call',
		value: TimelineTypeEnum.Call,
	},
	{
		label: 'Email',
		value: TimelineTypeEnum.Email,
	},
	{
		label: 'Direct Message',
		value: TimelineTypeEnum.DirectMessage,
	},
	{
		label: 'Note',
		value: TimelineTypeEnum.Note,
	},
];

export enum RealEstateExperience {
	Beginner = 1,
	Intermediate = 2,
	Advanced = 3,
}

export enum SoonLookingPeriod {
	Now = 1,
	OneMonth = 2,
	ThreePlusMonths = 3,
}

export enum CashAvailableToInvestSize {
	ZeroFive = 1,
	FiveTen = 2,
	TenTwentyFive = 3,
	TwentyFivePlus = 4,
}

export const mapRealEstateExperience = {
	[RealEstateExperience.Beginner]: 'Beginner (I own 0 properties)',
	[RealEstateExperience.Intermediate]: 'Intermediate (I own 2-3 properties)',
	[RealEstateExperience.Advanced]: 'Advanced ( I own 5 plus properties)',
};

export const mapSoonLookingPeriod = {
	[SoonLookingPeriod.Now]: 'Now',
	[SoonLookingPeriod.OneMonth]: '1 month',
	[SoonLookingPeriod.ThreePlusMonths]: '3 plus months',
};
export const mapInvestmentAmount = {
	[CashAvailableToInvestSize.ZeroFive]: '0-5k',
	[CashAvailableToInvestSize.FiveTen]: '5-10k',
	[CashAvailableToInvestSize.TenTwentyFive]: '10-25k',
	[CashAvailableToInvestSize.TwentyFivePlus]: '25k+',
};

export const timeOptions = generateTimeOptions(10);

export const timelineDefaultValues = {
	type: 0,
	date: '',
	timeFrom: null,
	timeTo: null,
	topic: '',
	callNote: '',
	note: '',
	message: '',
	email: '',
	subject: '',
	bodyText: '',
	imageIdsToDelete: [],
	fileIdsToDelete: [],
};

export const RiskFactorTitles = {
	[RiskScoreFactor.PandaEnrollment]:
		'No PandaDoc after 24 hours of enrollment',
	[RiskScoreFactor.CourseCompletion]:
		'Did not complete 82% of course after 7 days',
	[RiskScoreFactor.DmResponse]: 'No Response to DM after 24 hours',
	[RiskScoreFactor.PlatformLogin]: 'No login to Section 8 Pro after 24 hours',
	[RiskScoreFactor.CoursePlusResponse]:
		'Did not complete 82% of course after 7 days and no response to DM after 24 hours',
	[RiskScoreFactor.CommunityEnrollment]:
		'No Enrollment into Community after 18 hours',
};
