import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuth } from 'oidc-react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import axios from 'axios';
import { userManager } from '../../utils';

const verifySession = (token: string) =>
	axios.get(
		(import.meta.env.VITE_BASE_URL || 'http://localhost:3000') +
			'/api/admin/verify',
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);

export const ProtectedArea: React.FC<PropsWithChildren> = ({ children }) => {
	const { userData } = useAuth();
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		if (userData) {
			verifySession(userData.access_token)
				.then(() => {
					setLoading(false);
					navigate('/');
				})
				.catch(() => {
					userManager.signoutRedirect({
						extraQueryParams: {
							returnUrl: import.meta.env.VITE_OIDC_REDIRECT_URI,
						},
					});
				});
		}
	}, [userData]);

	if (loading) {
		return <Loader centerHeight="100vh" />;
	}

	if (userData) {
		return <>{children}</>;
	} else {
		return null;
	}
};
