import React from 'react';
import dayjs from 'dayjs';
import {
	Table,
	TableContainer,
	Tbody,
	Text,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { RenderTd, RenderTh } from '../../../../../components';
import { SectionWrapper } from './SectionWrapper';
import { USDateFormat } from '../../../../../constants';
import { UserRoleEnum } from '../../../types';

interface LoginActivityProps {
	lastLoginDate?: string;
	loginCount?: number;
	roleId?: number;
	dmsToTomCruzCount?: number | null;
}

export const LoginActivity: React.FC<LoginActivityProps> = ({
	lastLoginDate,
	loginCount,
	roleId,
	dmsToTomCruzCount,
}) => {
	return (
		<SectionWrapper borderRadius={'10px'}>
			<Text fontWeight={'700'} variant={'bodyLarge'}>
				Login & Activity Data
			</Text>

			<TableContainer
				mt={'15px'}
				borderRadius={'10px'}
				border={'1px solid #1C2959'}>
				<Table variant="simple" size={'sm'}>
					<Thead>
						<Tr bg={'lightBlue'}>
							<RenderTh
								textAlign={'center'}
								value={'Last Login Date'}
								width={
									roleId == UserRoleEnum.Guest ? '50%' : '16%'
								}
								borderRightColor={'#ffffff'}
							/>
							<RenderTh
								textAlign={'center'}
								value={'Logins'}
								width={
									roleId == UserRoleEnum.Guest ? '50%' : '16%'
								}
								borderRightColor={
									roleId == UserRoleEnum.Guest
										? '#1C2959'
										: 'undefined'
								}
							/>
							{roleId !== UserRoleEnum.Guest ? (
								<RenderTh
									textAlign={'center'}
									borderRightColor={'#1C2959'}
									value={'DM to Tom Cruz'}
								/>
							) : null}
						</Tr>
					</Thead>
					<Tbody bg={'#FFFFFF'}>
						<Tr>
							<RenderTd
								textAlign={'center'}
								borderRight={'1.5px solid #1C2959'}
								value={
									lastLoginDate
										? dayjs(lastLoginDate).format(
												USDateFormat,
										  )
										: '-'
								}
							/>
							<RenderTd
								textAlign={'center'}
								borderRight={
									roleId !== UserRoleEnum.Guest
										? '1.5px solid #1C2959'
										: 'undefined'
								}
								value={loginCount || '-'}
							/>
							{roleId !== UserRoleEnum.Guest ? (
								<RenderTd
									textAlign={'center'}
									value={String(dmsToTomCruzCount || '-')}
								/>
							) : null}
						</Tr>
					</Tbody>
				</Table>
			</TableContainer>
		</SectionWrapper>
	);
};
