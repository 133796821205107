import { FC } from 'react';
import { TableCellProps, Td, Text } from '@chakra-ui/react';

interface RenderTdProps extends TableCellProps {
	value: string | number;
}

export const RenderTd: FC<RenderTdProps> = ({ value, ...props }) => {
	// const isTextTooLong = typeof value === 'string' && value.length > 16;

	return (
		<Td
			fontWeight={'500'}
			fontSize={'16px'}
			color={'darkBlue'}
			p={'15px 15px'}
			border={'none'}
			{...props}>
			{/*{isTextTooLong ? (*/}
			{/*	<Tooltip label={value} aria-label="Text Tooltip">*/}
			{/*		<Text noOfLines={1}>{value}</Text>*/}
			{/*	</Tooltip>*/}
			{/*) : (*/}
			{/*)}*/}
			<Text
				sx={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}>
				{value}
			</Text>
		</Td>
	);
};
