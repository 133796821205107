import React from 'react';
import {
	Flex,
	Text,
	FormControl,
	FormLabel,
	Switch,
	Tooltip,
	Box,
} from '@chakra-ui/react';
import { colors } from '../../../../../theme/colors';
import { Icon } from '../../../../../components';
import { useUpdateRiskScoreMailingMutation } from '../../../hooks';

interface IActionResponsesHeaderProps {
	userId: number;
	isMailingDisabled?: boolean;
}

export const ActionResponsesHeader: React.FC<IActionResponsesHeaderProps> = ({
	userId,
	isMailingDisabled,
}) => {
	const onUpdateMailingSettingMutation = useUpdateRiskScoreMailingMutation();

	const onChange = (value: boolean) => {
		onUpdateMailingSettingMutation.mutate({ id: userId, value });
	};

	return (
		<Flex align="center" justify="space-between" mt="24px">
			<Text color="black" fontSize="18px" fontWeight={600}>
				Action-responses:
			</Text>
			<FormControl display="flex" alignItems="center" gap="5px" w="auto">
				<FormLabel
					htmlFor="email-alerts"
					m="0"
					fontSize="16px"
					fontWeight={600}
					color="darkBlue">
					Sending emails
				</FormLabel>
				<Tooltip
					label="The Risk Score emails will be sent only for 5 days from the First Sign In of User."
					placement="left"
					padding="16px"
					background="white"
					borderRadius="8px"
					color="lightText"
					fontSize="14px"
					fontWeight={600}
					w="354px"
					maxW="fit-content">
					<Box>
						<Icon
							name="info-circle"
							height="20px"
							width="20px"
							color={colors.blueText}
						/>
					</Box>
				</Tooltip>
				<Switch
					size="lg"
					isChecked={!isMailingDisabled}
					onChange={ev => {
						return onChange(!ev.target.checked);
					}}
					sx={{
						'> span': {
							bg: colors.dropBlue,
						},
					}}
					_checked={{
						'> span': {
							bg: colors.lightBlue,
						},
					}}
				/>
			</FormControl>
		</Flex>
	);
};
