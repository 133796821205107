import React from 'react';
import { LeftPaginationArrowIcon } from '../../../assets/icons';
import { IconButton } from '@chakra-ui/react';

interface IExpandedButton {
	menuExpanded: boolean;
	onClick: () => void;
}
export const ExpandedButton: React.FC<IExpandedButton> = ({
	menuExpanded,
	onClick,
}) => {
	return (
		<IconButton
			onClick={onClick}
			display={{ base: 'none', md: 'inline-flex' }}
			transition={'background .2s, transform .2s'}
			right={-4}
			top={'32px'}
			size="sm"
			borderRadius="4px"
			transform={`translateY(-50%) rotate(${
				menuExpanded ? '0' : '180deg'
			})`}
			position={'absolute'}
			background={'inactiveBlue'}
			_hover={{ bg: 'blueText' }}
			aria-label={'Expand icon'}
			icon={
				<LeftPaginationArrowIcon
					fillColor={'#FFFFFF'}
					width={20}
					height={20}
				/>
			}
		/>
	);
};
