import React from 'react';
import {
	Box,
	Text,
	Button,
	Flex,
	HStack,
	chakra,
	StackDivider,
	Divider,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Icon } from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import { RiskScoreAction } from '../../../types';
import { USDateFormat } from '../../../../../constants';
import { ActionResponseFactor } from './ActionResponseFactor';
import { RiskFactorTitles } from '../../../constants';

interface IActionResponseProps extends RiskScoreAction {
	onViewMore: VoidFunction;
}

export const ActionResponse: React.FC<IActionResponseProps> = ({
	createdAt,
	text,
	factor,
	attempt,
	type, // 1 for now
	onViewMore,
}) => {
	return (
		<Box
			bg="white"
			borderRadius="10px"
			p="20px"
			border="1px solid #EBF0F9"
			_hover={{
				background: 'background',
				'> hr:first-of-type': {
					borderColor: 'softBlue.100',
				},
			}}
			transition="all .3s">
			<ActionResponseFactor
				title={RiskFactorTitles[factor]}
				retryNumber={attempt}
			/>

			<Divider
				w="full"
				opacity={1}
				borderColor="background"
				my={3}
				transition="border 0.3s ease"
			/>

			<HStack spacing={4} align="flex-start">
				<Icon
					name="mail"
					height="24px"
					width="24px"
					color={colors.inactiveBlue}
				/>
				<Box>
					<Text color="lightText" fontSize="16px" fontWeight={500}>
						Type:{' '}
						<chakra.span fontWeight={600} color="darkBlue">
							Email sent
						</chakra.span>
					</Text>
					<HStack
						divider={<StackDivider borderColor="dropBlue" />}
						mt="4px"
						spacing="8px">
						<Text
							color="inactiveBlue"
							fontSize="14px"
							fontWeight={500}>
							{dayjs(createdAt).format(USDateFormat)}
						</Text>
						<Text
							color="inactiveBlue"
							fontSize="14px"
							fontWeight={500}>
							{dayjs(createdAt).format('hh:mm A')}
						</Text>
					</HStack>
				</Box>
			</HStack>
			<Flex mt="8px" align="flex-end">
				<Text
					color="darkBlue"
					fontSize="14px"
					fontWeight={500}
					noOfLines={2}
					whiteSpace="break-spaces">
					{text}
				</Text>
				<Button
					variant="link"
					fontSize="14px"
					color="inactiveBlue"
					height="fit-content"
					flexShrink={0}
					onClick={onViewMore}>
					View More
				</Button>
			</Flex>
		</Box>
	);
};
