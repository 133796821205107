import { useQuery } from 'react-query';
import { ApiResponse } from '../../../types';
import Api from '../../../api/api.base';
import { Alerter } from '../../../utils/Alerter';
import { CourseItemType } from '../types';

export const useGetCourses = () => {
	const { isFetching, data } = useQuery<
		ApiResponse<{
			courses: CourseItemType[];
		}>
	>({
		queryKey: 'courses',
		queryFn: async () => Api.get('/api/admin/courses', {}),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Error while getting courses');
		},
	});

	return {
		isLoading: isFetching,
		courses: data?.value?.courses,
	};
};
