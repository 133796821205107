import { UserManager } from 'oidc-react';
import { OIDC_CONFIG } from '../constants';

export const userManager = new UserManager({
	...OIDC_CONFIG,
	// userStore: new WebStorageStateStore({ store: window.sessionStorage }),
	loadUserInfo: true,
	revokeTokensOnSignout: true,
	revokeTokenTypes: ['refresh_token'],
});
