import React from 'react';
import {
	Box,
	Text,
	HStack,
	chakra,
	StackDivider,
	Stack,
	useMediaQuery,
} from '@chakra-ui/react';
import { ActivityMarker } from '../../../../../assets/icons';
import { colors } from '../../../../../theme/colors';
import { Icon } from '../../../../../components';
import { USDateFormat } from '../../../../../constants';
import dayjs from 'dayjs';

interface IFactorCardProps {
	name: string;
	value: number;
	state?: boolean | null;
	triggerDate: string | null;
	closingDate: string | null;
}

export const FactorCard: React.FC<IFactorCardProps> = ({
	value,
	name,
	state,
	triggerDate,
	closingDate,
}) => {
	const [isSmallerThan1300px] = useMediaQuery('(max-width: 1300px)');

	const getIcon = () => {
		if (state === null) {
			return <ActivityMarker color={colors.lightGray100} />;
		}
		if (typeof state === 'boolean') {
			if (state) {
				return <ActivityMarker color={colors.inactiveBlue} />;
			} else {
				return (
					<Icon
						name="lock"
						width="24px"
						height="24px"
						color={colors.lightGray200}
					/>
				);
			}
		}
		return null;
	};

	const getBackground = () => {
		if (state === null) {
			return 'background';
		}
		if (typeof state === 'boolean') {
			if (state) {
				return '#FBEDED';
			} else {
				return '#F0F0F0';
			}
		}
		return 'white';
	};

	return (
		<Box bg={getBackground()} borderRadius="10px" height="auto" p="20px">
			<HStack spacing="16px" align="flex-start">
				{getIcon()}
				<Box>
					<Text color="darkBlue" fontWeight={600} fontSize="16px">
						{name}
					</Text>
					<Text
						color="inactiveBlue"
						fontSize="14px"
						fontWeight={500}
						mt="4px">
						Point value: {value}
					</Text>
					<Stack
						direction={{
							base: 'column',
							sm: 'row',
							md: 'column',
							lg: 'row',
						}}
						gap="8.5px"
						spacing={0}
						mt="10px"
						divider={
							<StackDivider
								borderColor="dropBlue"
								display={{
									base: 'none',
									sm: 'block',
									md: 'none',
									lg: 'block',
								}}
							/>
						}>
						<Text
							color="lightText"
							fontSize="14px"
							fontWeight={500}>
							Trigger Date:{' '}
							<chakra.span
								color="inactiveBlue"
								display={{
									base: 'inline',
									lg: isSmallerThan1300px
										? 'block'
										: 'inline',
								}}>
								{triggerDate
									? dayjs(triggerDate).format(USDateFormat)
									: 'N/A'}
							</chakra.span>
						</Text>
						<Text
							color="lightText"
							fontSize="14px"
							fontWeight={500}>
							Closing Date:{' '}
							<chakra.span
								color="inactiveBlue"
								display={{
									base: 'inline',
									lg: isSmallerThan1300px
										? 'block'
										: 'inline',
								}}>
								{closingDate
									? dayjs(closingDate).format(USDateFormat)
									: 'N/A'}
							</chakra.span>
						</Text>
					</Stack>
				</Box>
			</HStack>
		</Box>
	);
};
