import { BaseTypeIcon } from '../../types';

export const PlusIcon: React.FC<BaseTypeIcon> = ({
	fillColor,
	width = 24,
	height = 24,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 5V19M5 12H19"
				stroke={fillColor || 'white'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
