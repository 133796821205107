import {
	Box,
	Button,
	Flex,
	HStack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { EditUserSection } from '../EditUserSection';
import React, { FC } from 'react';
import { faButton, greenButton } from '../../../../../../theme/elementStyles';
import {
	useChangeUser2faAuth,
	useChangeUser2faAuthState,
} from '../../../../../Users/hooks';
import { useParams } from 'react-router-dom';
import {
	Change2faAuthDialog,
	ResetUser2faAuthState,
} from '../../../../../Users/modals';
import { colors } from '../../../../../../theme/colors';

interface ChangeAuthStatusSectionProps {
	is2faEnabled: boolean;
	isAuthenticatorConfigured: boolean;
	refetchUserData: VoidFunction;
}

export const ChangeAuthStatusSection: FC<ChangeAuthStatusSectionProps> = ({
	is2faEnabled,
	isAuthenticatorConfigured,
	refetchUserData,
}) => {
	const { id } = useParams();

	// 2FA Authentication disable/enable
	const changeUserAuthStatus = useChangeUser2faAuth();
	const {
		isOpen: isAuthConfirmOpen,
		onClose: onAuthConfirmClose,
		onOpen: onAuthConfirmOpen,
	} = useDisclosure();
	const onStartUserAuthChangeDialog = () => {
		onAuthConfirmOpen();
	};
	// ============================

	// 2FA Authentication reset state
	const changeUser2faAuthState = useChangeUser2faAuthState();
	const {
		isOpen: isAuthStateConfirmOpen,
		onClose: onAuthStateConfirmClose,
		onOpen: onAuthStateConfirmOpen,
	} = useDisclosure();
	const onStartUserAuthStateChangeDialog = () => {
		onAuthStateConfirmOpen();
	};
	// ============================

	return (
		<>
			<Box flex={'1'}>
				<EditUserSection title={'Authentication'} isDisabled={false}>
					<Flex align="center" justify="space-between">
						<Flex flexDir={'column'} gap={'2px'}>
							<HStack align={'start'}>
								<Text
									fontSize={'16px'}
									color={'lightText'}
									whiteSpace={['normal', 'nowrap']}>
									Status:
								</Text>

								<Text
									fontSize={'16px'}
									color={'darkBlue'}
									whiteSpace={['normal', 'nowrap']}
									fontWeight={700}>
									{is2faEnabled ? 'Enabled' : 'Disabled'}
								</Text>
							</HStack>
							{is2faEnabled ? (
								<Text
									fontSize={'14px'}
									color={'lightBlueButton.600'}
									whiteSpace={['normal', 'nowrap']}>
									{is2faEnabled
										? isAuthenticatorConfigured
											? 'Configured'
											: 'Unconfigured'
										: null}
								</Text>
							) : null}
						</Flex>
						<Flex gap={'10px'}>
							{is2faEnabled ? (
								<Button
									{...faButton}
									w={'100px'}
									isLoading={changeUser2faAuthState.isLoading}
									isDisabled={!isAuthenticatorConfigured}
									// loadingText={'Updating...'}
									bg={'inherit'}
									fontWeight={600}
									fontSize={'16px'}
									color={'lightBlueButton.500'}
									border={`2px solid ${colors.greenButton['500']}`}
									_disabled={{
										color: 'lightGray200',
										borderColor: 'lightGray200',
										cursor: 'not-allowed',
										_hover: {
											bg: 'inherit',
										},
									}}
									onClick={onStartUserAuthStateChangeDialog}>
									Reset
								</Button>
							) : null}
							<Button
								{...faButton}
								minW={'100px'}
								isLoading={changeUserAuthStatus.isLoading}
								loadingText={'Updating...'}
								bg={
									is2faEnabled
										? 'dashboardRed'
										: 'greenButton.500'
								}
								fontWeight={700}
								onClick={onStartUserAuthChangeDialog}>
								{is2faEnabled ? 'Disable' : 'Enable'}
							</Button>
						</Flex>
					</Flex>
				</EditUserSection>
			</Box>
			<Change2faAuthDialog
				is2faEnabled={is2faEnabled}
				isOpen={isAuthConfirmOpen}
				onClose={onAuthConfirmClose}
				isLoading={changeUserAuthStatus.isLoading}
				confirmCb={() => {
					changeUserAuthStatus.mutate(
						{ userId: id },
						{
							onSuccess: () => {
								refetchUserData();
								onAuthConfirmClose();
							},
						},
					);
				}}
			/>
			<ResetUser2faAuthState
				isOpen={isAuthStateConfirmOpen}
				onClose={onAuthStateConfirmClose}
				isLoading={changeUser2faAuthState.isLoading}
				confirmCb={() => {
					changeUser2faAuthState.mutate(
						{ userId: id },
						{
							onSuccess: () => {
								refetchUserData();
								onAuthStateConfirmClose();
							},
						},
					);
				}}
			/>
		</>
	);
};
