import React from 'react';
import { Box, HStack, StackProps, Text, TextProps } from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import { ActionResponseRetryIndicator } from './ActionResponseRetryIndicator';

interface IActionResponseFactorProps {
	title: string;
	retryNumber?: number;
	sectionWrapperProps?: StackProps;
	titleProps?: TextProps;
}

export const ActionResponseFactor: React.FC<IActionResponseFactorProps> = ({
	title,
	retryNumber,
	sectionWrapperProps,
	titleProps,
}) => {
	return (
		<HStack spacing={4} {...sectionWrapperProps}>
			<Box boxSize="24px" alignSelf="flex-start">
				<Icon
					name="exclamation-mark-circle"
					height="24px"
					width="24px"
					color={colors.dashboardRed}
				/>
			</Box>
			<Text
				color="lightBlue"
				title={title}
				fontWeight={600}
				flex={1}
				noOfLines={1}
				{...titleProps}>
				{title}
			</Text>

			<ActionResponseRetryIndicator retryNumber={retryNumber} />
		</HStack>
	);
};
