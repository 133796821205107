import { useMutation } from 'react-query';
import { API_IDENTITY } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils/Alerter';

export const useChangeUser2faAuth = () => {
	return useMutation<ApiResponseEmpty, unknown, { userId?: string }>({
		mutationFn: payload => {
			if (!payload.userId) {
				throw new Error('Missing userId in @@@useChangeUser2faAuth');
			} else {
				return API_IDENTITY.post(
					`api/admin/users/${payload.userId}/authenticator/change`,
					{},
				);
			}
		},

		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response.errors?.[0]?.message);
				return;
			}
			Alerter.success(
				response.messages?.[0]?.message ||
					'Two factor status was changed',
			);
		},
	});
};
