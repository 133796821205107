import React from 'react';
import {
	Avatar,
	Box,
	Center,
	CloseButton,
	Flex,
	HStack,
	IconButton,
	Spinner,
	Text,
	VStack,
} from '@chakra-ui/react';
import { LinkItems } from '../../../constants';
import { NavItem } from './NavItem';
import { LogoutIcon } from '../../../assets/icons';
import { useAuth } from 'oidc-react';
import { ICurrentUser } from '../../../types';
import { NavAccordion } from './NavAccordion';
import { Icon } from '../../Icon/Icon';

interface IMenuContent {
	onClose: () => void;
	isLoading: boolean;
	data?: ICurrentUser;
}
export const MenuContent: React.FC<IMenuContent> = ({
	onClose,
	isLoading,
	data,
}) => {
	const fullName = `${data?.firstName} ${data?.lastName}`;

	const { signOut } = useAuth();
	return (
		<Flex
			direction="column"
			align="space-between"
			bg="lightBlue"
			transition=".3s"
			w={{ base: 'full' }}
			overflowX="hidden"
			h="100dvh"
			pos="fixed">
			<Flex
				px={'16px'}
				py={6}
				justifyContent="space-between"
				alignItems="center"
				boxShadow="0px 4px 4px -4px #0F1B4999">
				<Box w="204px" minW="204px" h="32px">
					<Icon
						name="logo-admin"
						width="204px"
						height="32px"
						color="transparent"
					/>
				</Box>
				<CloseButton
					color="white"
					display={{ base: 'flex' }}
					onClick={onClose}
				/>
			</Flex>
			<VStack
				py={6}
				flexGrow={1}
				spacing={1}
				px={'16px'}
				overflowX="clip"
				overflowY="auto"
				className="vertical-scroll-main">
				{LinkItems.map(item => {
					if (item.text === 'Community') {
						return (
							<NavAccordion key={item.id} menuExpanded={true}>
								{item.innerLinks?.map(link => {
									return (
										<NavItem
											key={link.text}
											iconName={link.iconName}
											link={link.link}
											isAccordionItem={true}
											menuExpanded={true}
											horizontalStackProps={{
												p: '10px',
											}}>
											Verification
										</NavItem>
									);
								})}
							</NavAccordion>
						);
					}
					return (
						<NavItem
							key={item.text}
							iconName={item.iconName}
							link={item.link}
							menuExpanded={true}>
							{item.text}
						</NavItem>
					);
				})}
			</VStack>

			<Box px={'16px'} py={5} boxShadow="0px -4px 4px -4px #0F1B4999">
				{isLoading ? (
					<Center>
						<Spinner size="lg" color="white" />
					</Center>
				) : (
					<Flex align="center">
						<HStack spacing="10px">
							<Avatar name={fullName} boxSize={'40px'} />

							<VStack
								w="fit-content"
								align="flex-start"
								flex={1}
								spacing={0}>
								<Text
									title={fullName}
									color="dropBlue"
									fontSize="14px"
									lineHeight="21px"
									fontWeight={600}>
									{fullName}
								</Text>
								<Text
									title={data?.email}
									color="dropBlue"
									fontSize="12px"
									lineHeight="18px"
									fontWeight={500}>
									{data?.email}
								</Text>
							</VStack>
						</HStack>

						<IconButton
							ml={'auto'}
							onClick={signOut}
							background={'none'}
							_hover={{
								background: 'blackAlpha.500',
							}}
							_active={{
								background: 'blackAlpha.500',
							}}
							aria-label={'sign-out'}
							icon={<LogoutIcon />}
						/>
					</Flex>
				)}
			</Box>
		</Flex>
	);
};
