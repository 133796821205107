import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Header, TransactionsHistoryTable } from './components';
import { useGetTransactionHistory } from './hooks';

const TransactionHistory: React.FC = () => {
	const [pickedCards, setPickedCards] = useState<string[]>([]);

	const [date, setDate] = useState<string | null>(null);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);

	const { isLoading, isFetching, data } = useGetTransactionHistory(
		pageSize,
		currentPage,
		date,
		pickedCards,
	);

	const { transactions, totalCount } = data || {};

	const addCard = (card: string) => {
		setPickedCards(prevState => [...prevState, card]);
	};
	const removeCard = (cardToRemove: string) => {
		setPickedCards(prevState =>
			prevState.filter(card => card !== cardToRemove),
		);
	};

	return (
		<Box>
			<Header
				date={date}
				setDate={setDate}
				addCard={addCard}
				removeCard={removeCard}
				pickedCards={pickedCards}
			/>
			<TransactionsHistoryTable
				transactions={transactions}
				totalCount={totalCount}
				isLoading={isLoading || isFetching}
				pageSize={pageSize}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				setPageSize={setPageSize}
			/>
		</Box>
	);
};
export default TransactionHistory;
