import { useQuery } from 'react-query';
import { API_IDENTITY } from '../../../api/api.base';
import { GuestUser } from '../types';
import { ApiResponse } from '../../../types';

export const useGetGuestUsers = (
	fullName?: string,
	email?: string,
	pageSize?: number,
	currentPage?: number,
) => {
	return useQuery<ApiResponse<{ count: number; items: GuestUser[] }>>(
		['guest-users', currentPage, pageSize, fullName, email],
		async () =>
			API_IDENTITY.get('/api/admin/guest-users', {
				currentPage: currentPage ? currentPage : undefined,
				pageSize: pageSize ? pageSize : undefined,
				fullName: fullName ? fullName : undefined,
				email: email ? email : undefined,
			}),
	);
};
