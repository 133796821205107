/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */

import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Center,
	Flex,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tooltip,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { EditIcon, SearchIcon } from '../../../../assets/icons';
import { colors } from '../../../../theme/colors';
import {
	INVITATION_STATUS_OPTIONS,
	RISK_STATUS_OPTIONS,
	SEARCH_BY_OPTIONS,
} from '../../constants';
import { IUser, UserRiskLevelEnum } from '../../types';
import {
	CustomCheckbox,
	ExtendedPagination,
	RenderTd,
	RenderTh,
	RiskTag,
} from '../../../../components';
import { toolTip } from '../../../../theme/elementStyles';
import { Link } from 'react-router-dom';
import { usePermissionGroupsStore } from '../../../../store';
import { normalizeDateInUsFormat } from '../../../../utils/normalizeDateInUsFormat';
import { InviteModal, DeleteUsersModal } from '../../modals';
import { ReinviteUsersModal } from '../../modals/ReinviteUsers/ReinviteUsersModal';
import { useGetUsers } from '../../hooks';
import { filterUniqueObjects, mainCheckboxStatus } from '../../helper';

export const Main: React.FC = () => {
	const [fullName, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [permissionId, setPermissionId] = useState<string | undefined>();
	const [inviteStatus, setInviteStatus] = useState<string | undefined>();
	const [riskLevelId, setRiskLevelId] = useState<string | undefined>();
	const [inviteModalOpen, setInviteModalOpen] = useState(false);
	const [searchByEmail, setSearchByEmail] = useState(false);
	const [userIdsForDeletion, setUserIdsForDeletion] = useState<number[]>([]);
	const [usersDataForDeletion, setUsersDataForDeletion] = useState<IUser[]>(
		[],
	);
	const [allUsersSelected, setAllUsersSelected] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { items } = usePermissionGroupsStore();

	const { refetch, isLoading, data } = useGetUsers(
		fullName,
		email,
		pageSize,
		currentPage,
		permissionId,
		inviteStatus,
		riskLevelId,
	);

	const handleUserIdForDeletion = (id: number) => {
		if (allUsersSelected) {
			setAllUsersSelected(false);
		}
		const [...tempArray] = userIdsForDeletion;
		const [...tempArray2] = usersDataForDeletion;
		if (userIdsForDeletion.includes(id)) {
			const index = tempArray.indexOf(id);
			tempArray.splice(index, 1);
			setUserIdsForDeletion(tempArray);
			if (data?.users) {
				for (let i = 0; i < tempArray2.length; i++) {
					if (tempArray2[i].id === id) {
						tempArray2.splice(i, 1);
						break;
					}
				}
				setUsersDataForDeletion(tempArray2);
			}
		} else {
			tempArray.push(id);
			setUserIdsForDeletion(tempArray);
			if (data?.users) {
				tempArray2.push(data?.users.filter(item => item.id === id)[0]);
				setUsersDataForDeletion(tempArray2);
			}
		}
	};

	const handleAllUsersForDeletion = () => {
		if (data?.users) {
			const [...tempUsersList]: IUser[] = data.users;
			const [...tempArray] = userIdsForDeletion;
			const [...tempArray2] = usersDataForDeletion;

			if (allUsersSelected) {
				for (let i = 0; i < tempUsersList.length; i++) {
					if (userIdsForDeletion.includes(tempUsersList[i].id)) {
						// ??? why 0
						const index = tempArray.indexOf(0);
						tempArray.splice(index, 1);
						tempArray2.splice(index, 1);
					}
				}
				setUserIdsForDeletion(tempArray);
				setUsersDataForDeletion(tempArray2);
				setAllUsersSelected(false);
			} else {
				for (let i = 0; i < tempUsersList.length; i++) {
					tempArray.push(tempUsersList[i].id);
					if (data?.users) {
						tempArray2.push(data?.users[i]);
					}
				}
				setAllUsersSelected(true);
				setUserIdsForDeletion([...new Set(tempArray)]);
				setUsersDataForDeletion([...filterUniqueObjects(tempArray2)]);
			}
		}
	};

	//change status modal open/close state controller
	useEffect(() => {
		if (searchByEmail) {
			setFullName('');
		} else {
			setEmail('');
		}
	}, [searchByEmail]);

	useEffect(() => {
		setCurrentPage(1);
	}, [fullName, email, permissionId, inviteStatus, riskLevelId]);

	useEffect(() => {
		if (data?.users) {
			mainCheckboxStatus(
				userIdsForDeletion,
				data.users,
				allUsersSelected,
				setAllUsersSelected,
			);
		}
	}, [data?.users, userIdsForDeletion]);

	return (
		<>
			<Flex
				direction={{ base: 'column', md: 'row' }}
				align="center"
				justifyContent="space-between"
				position={'relative'}>
				<Flex
					w={{ base: '100%', lg: 'auto' }}
					position={'absolute'}
					right={0}
					top={{ base: '10px', lg: '-68px' }}
					gap={2}>
					<Button
						hidden={!userIdsForDeletion.length}
						justifySelf={'end'}
						colorScheme="red"
						w={{ base: '100%', lg: 'auto' }}
						onClick={onOpen}>
						Delete users
					</Button>
					<Button
						colorScheme="lightBlueButton"
						w={{ base: '100%', lg: 'auto' }}
						onClick={() => setInviteModalOpen(true)}>
						{!usersDataForDeletion.length
							? 'Invite user(s)'
							: 'Reinvite user(s)'}
					</Button>
				</Flex>

				<InputGroup
					minW={{ base: '100%', md: '200px' }}
					maxW={{ base: '100%', md: '200px' }}
					mt={{ base: '70px', lg: 0 }}>
					<InputLeftElement h="46px" alignItems="center">
						<SearchIcon />
					</InputLeftElement>
					<Input
						value={searchByEmail ? email : fullName}
						onChange={e => {
							if (searchByEmail) {
								setEmail(e.target.value);
							} else {
								setFullName(e.target.value);
							}
						}}
						height="46px"
						placeholder="Search"
						color={colors.darkBlue}
						fontWeight={500}
						bgColor="white"
						border={`1px solid ${colors.dropBlue}`}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						borderRadius="10px"
						_placeholder={{
							color: colors.lightGray,
						}}
					/>
				</InputGroup>
				<Flex
					w={{ base: '100%', md: '100%' }}
					p={{ base: '10px 0px 0px 0px', md: '10px' }}
					mt={{ base: 0, md: '70px', lg: 0 }}
					columnGap="15px">
					<Select
						maxW={{ base: '100%', md: '150px' }}
						onChange={e => {
							if (e.target.value === '1') {
								setSearchByEmail(false);
							} else {
								setSearchByEmail(true);
							}
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						{SEARCH_BY_OPTIONS.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>
					<Select
						maxW={{ base: '100%', md: '200px' }}
						defaultValue="0"
						onChange={e => {
							if (e.target.value === '0') {
								setPermissionId(undefined);
							} else {
								setPermissionId(e.target.value);
							}
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						<option value={'0'}>All Permissions</option>
						{items.map(elem => (
							<option key={elem.id} value={elem.id.toString()}>
								{elem.name}
							</option>
						))}
					</Select>
					<Select
						maxW={{ base: '100%', md: '220px' }}
						onChange={e => {
							if (e.target.value === '0') {
								setInviteStatus(undefined);
							} else {
								setInviteStatus(e.target.value);
							}
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						{INVITATION_STATUS_OPTIONS.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>
					<Select
						maxW={{ base: '100%', md: '220px' }}
						onChange={e => {
							if (e.target.value === '0') {
								setRiskLevelId(undefined);
							} else {
								setRiskLevelId(e.target.value);
							}
						}}
						height="46px"
						borderRadius="10px"
						bgColor="white"
						color={colors.darkBlue}
						fontWeight={500}
						boxShadow="0px 0px 3px 0px rgba(139, 139, 139, 0.10)"
						border="none">
						{RISK_STATUS_OPTIONS.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</Select>
				</Flex>
			</Flex>
			<Box>
				<TableContainer
					mt="20px"
					border="1px solid"
					borderColor={colors.dropBlue}
					borderRadius="10px"
					boxShadow="none">
					<Table variant="simple">
						<Thead>
							<Tr>
								<Th
									bgColor={colors.dropBlue}
									borderRight="1px solid white"
									w="50px"
									px="13px">
									<CustomCheckbox
										isChecked={allUsersSelected}
										onChange={() =>
											handleAllUsersForDeletion()
										}
									/>
								</Th>
								{/* <RenderTh
									color={colors.darkBlue}
									value="Id"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/> */}
								<RenderTh
									color={colors.darkBlue}
									value="Email"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="First Name"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Last Name"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Invitation Sent Date"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Invitation Status"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Pandadoc Status"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Permission"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Risk Score"
									bgColor={colors.dropBlue}
									letterSpacing="0px"
									textAlign="center"
									minW="114px"
									w="114px"
								/>
								<RenderTh
									color={colors.darkBlue}
									value="Action"
									borderRightColor={colors.dropBlue}
									bgColor={colors.dropBlue}
									letterSpacing="0px"
								/>
							</Tr>
						</Thead>
						<Tbody>
							{isLoading ? (
								<Tr bg="white">
									<Td colSpan={10}>
										<Center>
											<Spinner />
										</Center>
									</Td>
								</Tr>
							) : (
								data?.users?.map((user, index) => {
									const lastItem =
										data?.users.length - 1 === index;
									const riskScore =
										user.riskLevel ===
										UserRiskLevelEnum.NONE
											? undefined
											: user.totalScore;
									return (
										<Tr key={index} bg="white">
											<Td
												w="50px"
												px="13px"
												py="9px"
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												borderRight={`1px solid ${colors.dropBlue}`}>
												<CustomCheckbox
													isChecked={userIdsForDeletion.includes(
														user.id,
													)}
													onChange={() =>
														handleUserIdForDeletion(
															user.id,
														)
													}
												/>
											</Td>
											<RenderTd
												value={user.email}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
												maxW="320px"
												isTruncated={true}
											/>
											<RenderTd
												value={user.firstName}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<RenderTd
												value={user.lastName}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<RenderTd
												value={
													user.invitationDate
														? normalizeDateInUsFormat(
																user.invitationDate,
														  )
														: '-'
												}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<RenderTd
												value={user.status}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<RenderTd
												value={
													user.isAgreementSigned
														? 'Yes'
														: 'No'
												}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>

											<RenderTd
												value={
													items.find(
														elem =>
															elem.id ===
															user.permissionId,
													)?.name || '-'
												}
												borderRight={`1px solid ${colors.dropBlue}`}
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												p="9px 16px"
											/>
											<Td
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}
												borderRight="1px solid"
												borderRightColor={
													colors.dropBlue
												}
												p={0}>
												<Center>
													<RiskTag
														score={riskScore}
													/>
												</Center>
											</Td>
											<Td
												py="9px"
												borderBottom={`1px solid ${
													lastItem
														? 'transparent'
														: colors.dropBlue
												}`}>
												<Flex
													gap="5px"
													justifyContent="center">
													<Tooltip
														{...toolTip}
														placement={'left-start'}
														label={'Edit user'}>
														<Link
															to={`/user/${user.id}`}>
															<IconButton
																aria-label="Edit user"
																bgColor="lightBlue"
																w="30px"
																minW="30px"
																h="30px"
																_hover={{}}
																_active={{}}
																_focusVisible={{}}>
																<EditIcon
																	width={20}
																	height={20}
																	fillColor="white"
																/>
															</IconButton>
														</Link>
													</Tooltip>
												</Flex>
											</Td>
										</Tr>
									);
								})
							)}
						</Tbody>
					</Table>
				</TableContainer>
				<ExtendedPagination
					currentPage={currentPage}
					gotoPage={val => setCurrentPage(val)}
					pageSize={pageSize}
					setPageSize={val => {
						setPageSize(val);
						setCurrentPage(1);
					}}
					totalCount={data?.totalCount || 0}
					currentItemsLength={data?.users?.length}
					perPageTextProps={{
						color: colors.lightGray,
						fontWeight: 500,
						fontSize: '16px',
					}}
					pagesTextProps={{
						color: colors.darkBlueTable[100],
						fontWeight: 500,
						fontSize: '16px',
					}}
					selectProps={{
						backgroundColor: 'white',
						color: colors.darkBlue,
						fontWeight: 500,
						border: 'none',
						boxShadow: '0px 0px 3px 0px rgba(139, 139, 139, 0.10)',
						minW: '74px',
						borderRadius: '10px',
						h: '46px',
					}}
					defaultPaginationProps={{
						mx: '0px',
						mt: '40px',
					}}
				/>
			</Box>
			<InviteModal
				isOpen={!usersDataForDeletion.length && inviteModalOpen}
				onClose={() => setInviteModalOpen(false)}
				refetch={refetch}
			/>
			<ReinviteUsersModal
				isOpen={usersDataForDeletion.length ? inviteModalOpen : false}
				onClose={() => setInviteModalOpen(false)}
				usersDataForDeletion={usersDataForDeletion}
				// @ts-ignore
				setUsersDataForDeletion={setUsersDataForDeletion}
				setUserIdsForDeletion={setUserIdsForDeletion}
			/>
			<DeleteUsersModal
				setUserIdsForDeletion={setUserIdsForDeletion}
				// @ts-ignore
				setUsersDataForDeletion={setUsersDataForDeletion}
				userIdsForDeletion={userIdsForDeletion}
				refetch={refetch}
				usersDataForDeletion={usersDataForDeletion}
				isOpen={isOpen}
				onClose={onClose}
			/>
		</>
	);
};
