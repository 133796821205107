import React, { useState } from 'react';
import { Flex, Text, Button, Select, Checkbox, VStack } from '@chakra-ui/react';
import { greenButton } from '../../../../../../theme/elementStyles';
import { useChangeGuestPermission } from '../../../../../Users/hooks';
import { usePermissionGroupsStore } from '../../../../../../store';

interface IGuestUserStatusProps {
	id?: number;
}

export const GuestUserStatus: React.FC<IGuestUserStatusProps> = ({ id }) => {
	const changeGuestPermissionMutation = useChangeGuestPermission();
	const [newPermId, setNewPermId] = useState(0);
	const [userNotify, setUserNotify] = useState(true);
	const [changingOpen, setChangingOpen] = useState(false);

	const { items } = usePermissionGroupsStore();

	const onChangeRole = () => {
		if (id) {
			changeGuestPermissionMutation.mutate(
				{
					isNotified: userNotify,
					permissionId: newPermId,
					userId: id,
				},
				{
					onSuccess(data, variables, context) {
						if (data.success) {
							setChangingOpen(false);
						}
					},
				},
			);
		}
	};

	return (
		<>
			<Flex justifyContent={'space-between'} alignItems={'center'}>
				<Flex gap={'5px'} alignItems={'center'} minH="40px">
					<Text color={'lightText'}>Role:</Text>
					<Text
						color={'darkBlue'}
						fontWeight={700}
						fontSize={'16px'}
						w={'94px'}
						variant={'bodyBig'}>
						User Guest
					</Text>
				</Flex>
				{!changingOpen ? (
					<Button
						{...greenButton}
						fontWeight={600}
						w={'210px'}
						p={'0 24px'}
						onClick={() => setChangingOpen(true)}
						h={'40px'}>
						Change to Main User
					</Button>
				) : null}
			</Flex>
			{changingOpen ? (
				<VStack
					mt={'15px'}
					gap={'10px'}
					justifyContent={'center'}
					alignItems={'start'}>
					<Select
						w="100%"
						border={'none'}
						height="66px"
						fontSize={'16px'}
						color={'darkBlue'}
						fontWeight={'500'}
						_placeholder={{ color: 'lightText' }}
						borderRadius="10px"
						placeholder="Select permission group"
						value={newPermId.toString()}
						onChange={ev => {
							setNewPermId(+ev.target.value);
						}}
						bg="background">
						{items.map(elem => (
							<option key={elem.id} value={elem.id.toString()}>
								{elem.name}
							</option>
						))}
					</Select>
					<Checkbox
						colorScheme="green"
						size={'lg'}
						isChecked={userNotify}
						onChange={ev => setUserNotify(ev.target.checked)}
						color={'darkBlue'}>
						Notify the user by mail of a role change
					</Checkbox>

					<Button
						{...greenButton}
						variant={'solid'}
						h={'40px'}
						w={'100%'}
						_disabled={{
							backgroundColor: '#DBDBDB',
							cursor: 'not-allowed',
						}}
						_hover={{ backgroundColor: '#DBDBDB' }}
						isDisabled={!newPermId}
						isLoading={changeGuestPermissionMutation.isLoading}
						onClick={onChangeRole}>
						Change Role
					</Button>
					<Button
						{...greenButton}
						h={'40px'}
						w={'100%'}
						variant={'ghost'}
						border={'2px solid #08BB4B'}
						borderRadius={'8px'}
						onClick={() => {
							setChangingOpen(false);
						}}
						color={'lightBlue'}>
						Cancel
					</Button>
				</VStack>
			) : null}
		</>
	);
};
