import React, { useState } from 'react';
import {
	Box,
	TableContainer,
	Table,
	Thead,
	Tr,
	Tbody,
	Flex,
	Button,
	useDisclosure,
} from '@chakra-ui/react';
import { CreatePermissionGroup } from './modals';
import { PermissionGroup } from '../../types';
import { usePermissionGroupsStore } from '../../store';
import { TableRow, DeletePermissionModal } from './components';
import { RenderTh } from '../../components';
import { colors } from '../../theme/colors';

export const PermissionGroups: React.FC = () => {
	const {
		isOpen: createGroupOpen,
		onClose: onCreateGroupClose,
		onOpen: onCreateGroupOpen,
	} = useDisclosure();

	const {
		isOpen: deletePermissionGroupIsOpen,
		onClose: onDeletePermissionGroupClose,
		onOpen: onDeletePermissionGroupOpen,
	} = useDisclosure();

	const [groupToEdit, setGroupToEdit] = useState<PermissionGroup | null>(
		null,
	);
	const [deletePermissionId, setDeletePermissionId] = useState(0);

	const { items } = usePermissionGroupsStore();

	return (
		<Box bg={colors.background}>
			<Flex justifyContent="flex-end" mb="10px">
				<Button
					onClick={onCreateGroupOpen}
					colorScheme="lightBlueButton">
					Create permission group
				</Button>
			</Flex>
			<TableContainer
				border="1px solid"
				borderColor="#e3e0e0"
				mt="20px"
				borderRadius="8px">
				<Table variant="simple">
					<Thead>
						<Tr>
							<RenderTh
								color={colors.darkBlue}
								value="Id"
								bgColor={colors.dropBlue}
								letterSpacing="0px"
								width="10%"
							/>
							<RenderTh
								color={colors.darkBlue}
								value="Name"
								bgColor={colors.dropBlue}
								letterSpacing="0px"
							/>
							<RenderTh
								color={colors.darkBlue}
								value="Creation Date"
								bgColor={colors.dropBlue}
								letterSpacing="0px"
							/>
							<RenderTh
								color={colors.darkBlue}
								value="Update Date"
								bgColor={colors.dropBlue}
								letterSpacing="0px"
							/>
							<RenderTh
								color={colors.darkBlue}
								value="Claims"
								bgColor={colors.dropBlue}
								letterSpacing="0px"
								width="50%"
							/>
							<RenderTh
								color={colors.darkBlue}
								value="Actions"
								bgColor={colors.dropBlue}
								letterSpacing="0px"
								borderRight="none"
							/>
						</Tr>
					</Thead>
					<Tbody>
						{items?.map((elem, index) => {
							const lastItem = items.length - 1 === index;
							return (
								<TableRow
									key={elem.id}
									{...elem}
									onEditOpen={() => {
										setGroupToEdit(elem);
										onCreateGroupOpen();
									}}
									onDelete={id => {
										setDeletePermissionId(id);
										onDeletePermissionGroupOpen();
									}}
									lastItem={lastItem}
								/>
							);
						})}
					</Tbody>
				</Table>
			</TableContainer>
			<CreatePermissionGroup
				isOpen={createGroupOpen}
				onClose={() => {
					onCreateGroupClose();
					setGroupToEdit(null);
				}}
				groupToEdit={groupToEdit}
			/>
			<DeletePermissionModal
				isOpen={deletePermissionGroupIsOpen}
				onClose={() => {
					onDeletePermissionGroupClose();
					setDeletePermissionId(0);
				}}
				id={deletePermissionId}
			/>
		</Box>
	);
};
