import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { Icon } from '../../../../../components';
import { colors } from '../../../../../theme/colors';

interface IActionResponseRetryIndicatorProps {
	retryNumber?: number;
}

export const ActionResponseRetryIndicator: React.FC<
	IActionResponseRetryIndicatorProps
> = ({ retryNumber }) => {
	const getTooltipLabel = useCallback(() => {
		switch (retryNumber) {
			case 2:
				return 'The risk is not closed, second notification retry has been made';
			case 3:
				return 'The risk is not closed, third (last) notification retry has been made';

			default:
				return '';
		}
	}, [retryNumber]);

	if (!retryNumber || retryNumber < 2) {
		return null;
	}
	return (
		<Tooltip
			label={getTooltipLabel()}
			bg="white"
			placement="bottom-end"
			color="lightText"
			fontSize="14px"
			lineHeight="21px"
			fontWeight={600}
			p={4}
			borderRadius="8px"
			boxShadow="0px 4px 10px #91919140">
			<HStack
				bg="white"
				borderRadius="30px"
				borderWidth="1px"
				borderColor="background"
				p="2.5px 10px"
				ml="auto"
				cursor="default"
				spacing={1}>
				<Box boxSize="16px">
					<Icon
						name="refresh"
						height="16px"
						width="16px"
						color={colors.blueText}
					/>
				</Box>
				<Text variant="bodyMedium" color="darkBlue">
					{retryNumber}
				</Text>
			</HStack>
		</Tooltip>
	);
};
