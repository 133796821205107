import React, { useEffect, Dispatch, SetStateAction } from 'react';
import {
	Box,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { blueButton, greenButton } from '../../../../theme/elementStyles';
import { IUser, GuestUser } from '../../types';
import { ReinviteUserEntity } from './components/ReinviteUserEntity';
import { useReinviteMultipleUsers } from '../../hooks';

interface ReinviteUsersModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	usersDataForDeletion: (IUser | GuestUser)[];
	setUsersDataForDeletion: Dispatch<SetStateAction<(IUser | GuestUser)[]>>;
	setUserIdsForDeletion: Dispatch<SetStateAction<number[]>>;
}

export const ReinviteUsersModal: React.FC<ReinviteUsersModalProps> = ({
	onClose,
	isOpen,
	usersDataForDeletion,
	setUsersDataForDeletion,
	setUserIdsForDeletion,
}) => {
	const { reinviteUsers, isLoading } = useReinviteMultipleUsers({
		setUsersDataForDeletion: setUsersDataForDeletion,
		setUserIdsForDeletion: setUserIdsForDeletion,
	});

	const handleClose = () => {
		onClose();
	};

	useEffect(() => {
		if (!usersDataForDeletion.length) {
			handleClose();
		}
	}, [usersDataForDeletion]);

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader pb={0}>Reinvite</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text fontWeight={'700'} my={'15px'} color={'darkBlue'}>
						{' '}
						Users
					</Text>
					<Text color={'inactiveButton.500'}>
						Resend invitation on email:
					</Text>
					<Box
						maxH={'255px'}
						overflowY={'scroll'}
						className={'custom-scroll'}
						bg={'background'}
						p={'10px'}
						borderRadius={'10px'}>
						{usersDataForDeletion?.map((user, index) => (
							<Box key={index}>
								<ReinviteUserEntity
									id={user.id}
									email={user.email}
									setUsersDataForDeletion={
										setUsersDataForDeletion
									}
									setUserIdsForDeletion={
										setUserIdsForDeletion
									}
								/>
							</Box>
						))}
					</Box>
				</ModalBody>
				<ModalFooter>
					<Button
						mr={3}
						onClick={handleClose}
						w={'50%'}
						{...blueButton}>
						Cancel
					</Button>
					<Button
						isLoading={isLoading}
						type="submit"
						onClick={() =>
							reinviteUsers(usersDataForDeletion, handleClose)
						}
						w={'50%'}
						{...greenButton}>
						Invite
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
