import React from 'react';
import {
	Box,
	Flex,
	FlexProps,
	IconButton,
	useColorModeValue,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { Icon } from '../../Icon/Icon';

interface MobileProps extends FlexProps {
	onOpen: () => void;
}

export const MobileNav: React.FC<MobileProps> = ({ onOpen, ...rest }) => {
	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 24 }}
			height="20"
			alignItems="center"
			bg={useColorModeValue('lightBlue', 'gray.900')}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
			justifyContent="space-between"
			{...rest}>
			<Box w="204px" minW="204px" h="32px">
				<Icon
					name="logo-admin"
					width="204px"
					height="32px"
					color="transparent"
				/>
			</Box>
			<IconButton
				variant="outline"
				onClick={onOpen}
				aria-label="open menu"
				icon={<FiMenu color="white" />}
				_hover={{
					bg: 'transparent',
				}}
				_active={{
					bg: 'transparent',
				}}
			/>
		</Flex>
	);
};
