import { FC, useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Text,
} from '@chakra-ui/react';
import { colors } from '../../../../theme/colors';

interface Change2faAuthDialog {
	isOpen: boolean;
	onClose: VoidFunction;
	is2faEnabled: boolean;
	confirmCb: VoidFunction;
	isLoading: boolean;
}

export const Change2faAuthDialog: FC<Change2faAuthDialog> = ({
	isOpen,
	onClose,
	is2faEnabled,
	isLoading,
	confirmCb,
}) => {
	const cancelRef = useRef(null);
	return (
		<AlertDialog
			isOpen={isOpen}
			onClose={onClose}
			leastDestructiveRef={cancelRef}>
			<AlertDialogOverlay>
				<AlertDialogContent minW={'505px'}>
					<AlertDialogHeader p={'20px'}>
						<Text
							fontSize={'18px'}
							fontWeight={'600'}
							color={'lightBlueButton.600'}>
							{is2faEnabled ? 'Disable' : 'Enable'}{' '}
							authentication
						</Text>
					</AlertDialogHeader>

					<AlertDialogBody p={'0 20px 20px 20px'}>
						<Text color={'lightBlueButton.600'}>
							Are you sure? You can always{' '}
							{is2faEnabled ? 'enable' : 'disable'} authentication
							later
						</Text>
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							onClick={onClose}
							border={`2px solid ${colors.greenButton['500']}`}
							h={'40px'}
							bg={'white'}
							color={'lightBlueButton.500'}>
							Cancel
						</Button>
						<Button
							bg={
								is2faEnabled
									? 'dashboardRed'
									: 'greenButton.500'
							}
							onClick={confirmCb}
							isLoading={isLoading}
							color={'white'}
							ml={'10px'}>
							{is2faEnabled ? 'Disable' : 'Enable'}
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
