import React from 'react';
import { SectionWrapper } from '../SectionWrapper';
import { Divider, VStack } from '@chakra-ui/react';
import { UserCredentials } from './UserCredentials';
import { PandadockFile } from './PandadockFile';
import { GuestUserStatus } from './GuestUserStatus';
import { UserRoleEnum } from '../../../../types';

interface IMainInfoSection {
	email?: string;
	phoneNumber?: string | null;
	invitationDate?: string;
	id?: number;
	firstName?: string;
	lastName?: string;
	invitationResendDate?: string;
	isDocumentCompleted: boolean;
	documentLink?: string;
	roleId: number | undefined;
}

export const MainInfoSection: React.FC<IMainInfoSection> = ({
	email,
	phoneNumber,
	invitationDate,
	id,
	roleId,
	firstName,
	lastName,
	invitationResendDate,
	isDocumentCompleted,
	documentLink,
}) => {
	return (
		<SectionWrapper borderRadius={'10px'}>
			<VStack align={'stretch'} spacing={'15px'}>
				<UserCredentials
					id={id}
					lastName={lastName}
					firstName={firstName}
					roleId={roleId}
					email={email}
					invitationDate={invitationDate}
					phoneNumber={phoneNumber}
					invitationResendDate={invitationResendDate}
				/>
				<Divider />
				{roleId !== UserRoleEnum.Guest ? (
					<PandadockFile
						documentLink={documentLink}
						isDocumentCompleted={Boolean(isDocumentCompleted)}
					/>
				) : (
					<GuestUserStatus id={id} />
				)}
			</VStack>
		</SectionWrapper>
	);
};
