import React from 'react';
import { Thead, Tr } from '@chakra-ui/react';
import { colors } from '../../../../theme/colors';
import { RenderTh } from '../../../../components';

export const TableHeader = () => (
	<Thead>
		<Tr>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="Date"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="NMI ID"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="Order ID"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="Full Name"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="Last 4 Credit Card Digits"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="Email"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="Amount"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
			/>
			<RenderTh
				width="25%"
				color={colors.darkBlue}
				value="Status"
				bgColor={colors.dropBlue}
				letterSpacing="0px"
				borderRight="none"
			/>
		</Tr>
	</Thead>
);
