import { BaseTypeIcon } from '../../types';

export const LeftPaginationArrowIcon: React.FC<BaseTypeIcon> = ({
	width,
	height,
	fillColor,
}) => {
	return (
		<svg
			width={width || '24'}
			height={height || '23'}
			viewBox="0 0 24 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15 5.75L9 11.5L15 17.25"
				stroke={fillColor || '#1C2959'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
