import {
	useEffect,
	useRef,
	useState,
	RefObject,
	Dispatch,
	SetStateAction,
} from 'react';
import {
	isFileTypeChecker,
	uploadMultipleGuestUsers,
	uploadMultipleUsers,
} from '../helper';
import { Alerter } from '../../../utils/Alerter';
import { IInviteMultipleUsersForm } from '../modals/InviteModal/tabs';

interface ReturnType {
	inputRef: RefObject<HTMLInputElement>;
	setFile: Dispatch<SetStateAction<File | null>>;
	buttonName: string;
	inviteAllBtnActive: boolean;
	startUploadingUsers: (data: IInviteMultipleUsersForm) => void;
	startUploadingGuestUsers: () => void;
	inviteAllBtnLoading: boolean;
}

export const useInviteMultipleUsers = (
	onClose: () => void,
	refetch?: any,
): ReturnType => {
	const [file, setFile] = useState<null | File>(null);
	const [buttonName, setButtonName] = useState('Upload a .csv file');
	const [inviteAllBtnActive, setInviteAllBtnActive] = useState(false);
	const [inviteAllBtnLoading, setInviteAllBtnLoading] = useState(false);

	const imageUploadInputRef1 = useRef<HTMLInputElement>(null);

	const startUploadingUsers = async (data: IInviteMultipleUsersForm) => {
		if (file) {
			setInviteAllBtnLoading(true);
			const formData = new FormData();
			formData.append('File', file);
			formData.append('PermissionId', data.permissionId);
			formData.append('Description', String(data.description));
			formData.append('Value', String(data.value));
			formData.append('AreDealsCreated', String(data.areDealsCreated));
			formData.append('IsContactCreated', String(data.isContactCreated));
			const res = await uploadMultipleUsers(formData);
			if (res.success) {
				refetch();
				onClose();
				setInviteAllBtnLoading(false);
				setInviteAllBtnActive(true);
				setButtonName('Upload a .csv file');
			} else {
				setInviteAllBtnLoading(false);
				setInviteAllBtnActive(true);
			}
		}
	};
	const startUploadingGuestUsers = async () => {
		if (file) {
			setInviteAllBtnLoading(true);
			const formData = new FormData();
			formData.append('File', file);
			const res = await uploadMultipleGuestUsers(formData);
			if (res.success) {
				refetch();
				onClose();
				setInviteAllBtnLoading(false);
				setInviteAllBtnActive(true);
				setButtonName('Upload a .csv file');
			} else {
				setInviteAllBtnLoading(false);
				setInviteAllBtnActive(true);
			}
		}
	};

	useEffect(() => {
		if (file) {
			if (isFileTypeChecker(file)) {
				if (imageUploadInputRef1.current) {
					imageUploadInputRef1.current.value = '';
				}
				// .length < 15 ? file.name : `${file.name.slice(0, 13)}...csv`
				setButtonName(file.name);
				setInviteAllBtnActive(true);
			} else {
				Alerter.error('Cannot upload file of this type');
			}
		}
	}, [file]);

	return {
		inputRef: imageUploadInputRef1,
		setFile: setFile,
		buttonName: buttonName,
		inviteAllBtnActive: inviteAllBtnActive,
		startUploadingUsers: startUploadingUsers,
		startUploadingGuestUsers: startUploadingGuestUsers,
		inviteAllBtnLoading: inviteAllBtnLoading,
	};
};
