/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from 'react';
import {
	Box,
	Button,
	Flex,
	HStack,
	VStack,
	Text,
	Skeleton,
	Image,
	Link,
} from '@chakra-ui/react';
// import { useDrag, useDrop } from 'react-dnd';
import { EditIcon, HighlightIcon } from '../../../assets/icons';
import { CourseItemType, StatusCourseEnum } from '../types';
import { colors } from '../../../theme/colors';

interface IHighlightedCourseProps {
	item: CourseItemType;
	isEditing: boolean;
	setIdToDeleteCourse: (id: number) => void;
	setIdToPublishCourse: (id: number) => void;
	setIdToUnpublishedCourse: (id: number) => void;
	setCourseToUpdateHighlight: (course: CourseItemType) => void;
	// onDropItem: (draggedItemId: number, newOrder: number) => void;
	// index: number;
	// onHoverItem: (draggedItemId: number, newOrder: number) => void;
}
export const HighlightedCourse: React.FC<IHighlightedCourseProps> = ({
	item,
	setIdToDeleteCourse,
	setIdToPublishCourse,
	setIdToUnpublishedCourse,
	setCourseToUpdateHighlight,
	isEditing,
	// onDropItem,
	// index,
	// onHoverItem,
}) => {
	const onAction = () => {
		if (item.statusId === StatusCourseEnum.Unpublished) {
			setIdToPublishCourse(item.id);
		}
		if (item.statusId === StatusCourseEnum.Published) {
			setIdToUnpublishedCourse(item.id);
		}
	};

	return (
		<Box
			mb="10px"
			padding={{ base: '20px 24px', md: '20px 40px' }}
			background={item.highlighted ? 'darkBlueTable.100' : 'white'}
			// opacity={isDragging ? 0.75 : 1}
			// ref={node => {
			// 	if (isEditing) {
			// 		drop(node);
			// 		preview(node);
			// 		drag(node);
			// 	}
			// }}
			// cursor={
			// 	isEditing ? (isDragging ? 'grabbing' : 'grab') : 'default'
			// }
		>
			<VStack spacing={'20px'} align={'stretch'}>
				<Flex justifyContent={'right'}>
					{isEditing ? (
						<Button
							onClick={() => setCourseToUpdateHighlight(item)}
							leftIcon={
								item.highlighted ? undefined : <HighlightIcon />
							}
							borderRadius={'50px'}
							colorScheme={
								item.highlighted
									? 'whiteButton'
									: 'lightBlueButton'
							}
							fontWeight={500}>
							<Text
								color={
									item.highlighted
										? colors.lightBlue
										: 'white'
								}>
								{item.highlighted
									? 'Remove highlight'
									: 'Highlight'}
							</Text>
						</Button>
					) : (
						<Button
							onClick={onAction}
							rightIcon={
								item.statusId ===
								StatusCourseEnum.Published ? undefined : (
									<EditIcon />
								)
							}
							borderRadius={'50px'}
							colorScheme={
								item.statusId === StatusCourseEnum.Published
									? 'greenButton'
									: 'inactiveButton'
							}>
							{item.statusId === StatusCourseEnum.Unpublished
								? 'Unpublished'
								: 'Published'}
						</Button>
					)}
				</Flex>
				<Flex gap="10px" flexDirection={{ base: 'column', md: 'row' }}>
					<Box w={{ base: 'full', md: '50%' }}>
						<Image
							objectFit={'contain'}
							fallback={
								<Skeleton h={'224px'} borderRadius={'10px'} />
							}
							borderRadius={'10px'}
							alt={'course image'}
							src={item?.imageLink ? item.imageLink : undefined}
							h={'224px'}
							w={'full'}
						/>
					</Box>
					<Flex
						justifyContent="space-around"
						direction="column"
						gap="10px"
						w={{ base: 'full', md: '50%' }}>
						<Box>
							<Text
								isTruncated={true}
								fontWeight={'700'}
								fontSize={'2xl'}
								color={item.highlighted ? 'white' : 'darkBlue'}>
								{item.title}
							</Text>
						</Box>
						<Text
							// isTruncated={true}
							fontWeight={'500'}
							noOfLines={2}
							color={item.highlighted ? 'white' : 'darkBlue'}>
							{item.description}
						</Text>
						<HStack spacing={'14px'}>
							<Button
								as={Link}
								to={
									isEditing
										? ''
										: `/courses/edit-course/${item.id}`
								}
								colorScheme={
									item.highlighted
										? 'whiteButton'
										: 'darkButton'
								}
								borderRadius="10px"
								color={
									item.highlighted ? 'darkButton' : 'white'
								}
								h={'59px'}
								w={'full'}
								isDisabled={isEditing}>
								Edit Course
							</Button>
							<Button
								onClick={() => {
									setIdToDeleteCourse(item.id);
								}}
								color={item.highlighted ? 'white' : 'darkBlue'}
								variant={'outline'}
								border={`2px solid ${
									item.highlighted
										? 'white'
										: colors.lightBlue
								}`}
								h={'59px'}
								w={'full'}
								borderRadius="10px"
								isDisabled={isEditing}
								_hover={
									!isEditing
										? {
												bg: item.highlighted
													? colors.darkBlueTable[100]
													: 'gray.100',
										  }
										: {}
								}>
								Delete Course
							</Button>
						</HStack>
					</Flex>
				</Flex>
			</VStack>
		</Box>
	);
};
