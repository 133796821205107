/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from 'react';
import { EditUserSection } from './EditUserSection';
import {
	Box,
	Button,
	chakra,
	Checkbox,
	Flex,
	ListItem,
	Select,
	Text,
	UnorderedList,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import { EditEntryTemplate } from './EditEntryTemplate';
import { SectionWrapper } from './SectionWrapper';
import {
	QueryObserverResult,
	RefetchOptions,
	RefetchQueryFilters,
} from 'react-query';
import {
	useChangePermission,
	useChangeUserRole,
	useChangeUserStatus,
	useUserReinvitation,
} from '../../../../Users/hooks';
import { usePermissionGroupsStore } from '../../../../../store';
import {
	returnUserRole,
	returnUserStatus,
	roles,
} from '../../../../Users/helper';
import { useFetchPermissionGroups } from '../../../../PermissionGroups/queries';
import { filterCurrentPermissions } from '../../../helper';
import { greenButton } from '../../../../../theme/elementStyles';
import { PasswordSection } from './ChangePasswordSection';
import { ConfirmAdminModal } from '../../../../../components';
import { ChangeAuthStatusSection } from './ChangeAuthStatusSection/ChangeAuthStatusSection';

interface PermissionsProps {
	credentials: {
		firstName: string;
		lastName: string;
		email: string;
		id: number;
		status: string;
		isSuspended: boolean;
		role: number | null;
		isPending: boolean;
		permissionId: number | null;
		isTwoFactorEnabled: boolean;
		isAuthenticatorConfigured: boolean;
	};
	refetch: <TPageData>(
		options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
	) => Promise<QueryObserverResult<any, unknown>>;
}

export const Permissions: React.FC<PermissionsProps> = ({
	credentials,
	refetch,
}) => {
	const { isLoading: reinviteReqLoading, setIdReinvite } =
		useUserReinvitation();
	const { startChangeStatusReq, isLoading: changeStatusLoading } =
		useChangeUserStatus(refetch);
	const { isLoading: changeRoleLoading, startChangeRoleReq } =
		useChangeUserRole(refetch);
	const {
		isLoading: changePermLoading,
		startChangePermissionReq,
		isChangingState,
		setIsChangingState,
		setNewPermId,
		readyToChange,
		newPermId,
		userNotify,
		setUserNotify,
	} = useChangePermission(refetch, credentials.permissionId);
	const {
		isOpen: isAdminConfirmOpen,
		onClose: onAdminConfirmClose,
		onOpen: onAdminConfirmOpen,
	} = useDisclosure();

	const { items } = usePermissionGroupsStore();

	const { data: permissions } = useFetchPermissionGroups();

	const onChangeRole = () => {
		if (credentials.role === 2 && !isAdminConfirmOpen) {
			onAdminConfirmOpen();
			return;
		}

		startChangeRoleReq(credentials?.id, credentials?.role);
	};

	return (
		<SectionWrapper borderRadius={'10px'}>
			<Flex gap={'10px'} flexDirection={{ base: 'column', xl: 'row' }}>
				<Box flex={'1'}>
					<EditUserSection
						title={'Change permission'}
						isDisabled={false}>
						{isChangingState ? (
							<Select
								w="100%"
								border={'none'}
								height="66px"
								fontSize={'16px'}
								color={'darkBlue'}
								fontWeight={'500'}
								_placeholder={{ color: 'lightText' }}
								borderRadius="10px"
								value={newPermId.toString()}
								onChange={ev => {
									setNewPermId(+ev.target.value);
								}}
								bg="white">
								{items.map(elem => (
									<option
										key={elem.id}
										value={elem.id.toString()}>
										{elem.name}
									</option>
								))}
							</Select>
						) : (
							<Flex
								bg={'white'}
								h={'66px'}
								p={'20px 20px 20px 16px'}
								alignItems={'center'}
								justifyContent={'space-between'}
								borderRadius={'10px'}>
								<Text color={'darkBlue'} fontWeight={'500'}>
									{items &&
									credentials &&
									credentials.permissionId
										? filterCurrentPermissions(
												credentials?.permissionId,
												items,
										  )[0].name
										: null}
								</Text>
								<Button
									{...greenButton}
									maxH={'40px'}
									onClick={() =>
										setIsChangingState(!isChangingState)
									}>
									Change
								</Button>
							</Flex>
						)}
						<Box
							bg={'white'}
							p={'15px 15px 15px 35px'}
							mt={'5px'}
							borderRadius={'10px'}>
							<Text
								color={'darkBlue'}
								fontWeight={'700'}
								my={'10px'}>
								Available pages
							</Text>
							<UnorderedList>
								{credentials &&
								credentials.permissionId &&
								permissions
									? filterCurrentPermissions(
											newPermId
												? newPermId
												: credentials.permissionId,
											permissions?.value.groups,
									  )[0].claimsIds.map((item, index) => (
											<ListItem key={index} my={'15px'}>
												{item.name}
											</ListItem>
									  ))
									: null}
							</UnorderedList>
						</Box>
						{isChangingState ? (
							<VStack
								mt={'15px'}
								gap={'10px'}
								justifyContent={'center'}
								alignItems={'start'}>
								<Checkbox
									display={readyToChange ? 'block' : 'none'}
									colorScheme="green"
									size={'lg'}
									isChecked={userNotify}
									onChange={ev =>
										setUserNotify(ev.target.checked)
									}
									color={'darkBlue'}>
									Notify the user by mail of a permission
									group change
								</Checkbox>
								<Button
									{...greenButton}
									variant={'solid'}
									h={'40px'}
									w={'100%'}
									_disabled={{
										backgroundColor: '#DBDBDB',
										cursor: 'not-allowed',
									}}
									_hover={{ backgroundColor: '#DBDBDB' }}
									isDisabled={!readyToChange}
									isLoading={changePermLoading}
									onClick={() =>
										startChangePermissionReq(
											credentials?.id,
										)
									}>
									Change Permission Group
								</Button>
								<Button
									{...greenButton}
									h={'40px'}
									w={'100%'}
									variant={'ghost'}
									border={'2px solid #08BB4B'}
									borderRadius={'8px'}
									onClick={() => {
										setNewPermId(
											credentials.permissionId as number,
										);
										setIsChangingState(!isChangingState);
									}}
									color={'lightBlue'}>
									Cancel
								</Button>
							</VStack>
						) : null}
					</EditUserSection>
				</Box>

				<Box flex={'1'}>
					<EditUserSection
						title={'Reinvite'}
						isDisabled={credentials?.isPending}>
						<EditEntryTemplate
							label="Resend invitation on email:"
							value={credentials?.email}
							isLoading={reinviteReqLoading}
							loadingText={'Reinviting...'}
							onClick={() => setIdReinvite(credentials.id)}
							buttonLabel="Accept"
						/>
					</EditUserSection>
				</Box>
			</Flex>
			<Flex gap={'10px'} flexDirection={{ base: 'column', xl: 'row' }}>
				<Box flex={'1'}>
					<EditUserSection title={'Change status'} isDisabled={false}>
						<EditEntryTemplate
							label="Status:"
							value={credentials?.status}
							isLoading={changeStatusLoading}
							loadingText={'Updating...'}
							onClick={() =>
								startChangeStatusReq(
									credentials?.id,
									!credentials?.isSuspended,
								)
							}
							buttonLabel={
								<>
									Change status on &nbsp;
									<chakra.span fontWeight={700}>
										&apos;
										{returnUserStatus(
											!credentials?.isSuspended,
										)}
										&apos;
									</chakra.span>
								</>
							}
						/>
					</EditUserSection>
				</Box>
				<Box flex={'1'}>
					<EditUserSection
						title={'Change role'}
						isDisabled={!credentials?.isPending}>
						<EditEntryTemplate
							label="Role:"
							value={roles[credentials.role || 0]}
							isLoading={changeRoleLoading}
							loadingText={'Updating...'}
							onClick={onChangeRole}
							buttonLabel={
								<>
									Change role on &nbsp;
									<chakra.span fontWeight={700}>
										&apos;
										{returnUserRole(credentials?.role)}
										&apos;
									</chakra.span>
								</>
							}
						/>
					</EditUserSection>
				</Box>
			</Flex>
			<Flex gap={'10px'} flexDirection={{ base: 'column', xl: 'row' }}>
				<PasswordSection userId={credentials.id} />
				<ChangeAuthStatusSection
					refetchUserData={() => refetch()}
					is2faEnabled={credentials.isTwoFactorEnabled}
					isAuthenticatorConfigured={
						credentials.isAuthenticatorConfigured
					}
				/>
			</Flex>
			<ConfirmAdminModal
				isOpen={isAdminConfirmOpen}
				onClose={onAdminConfirmClose}
				onConfirm={onChangeRole}
			/>
		</SectionWrapper>
	);
};
