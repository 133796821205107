import { FC, ReactNode } from 'react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Text,
} from '@chakra-ui/react';
import { Icon } from '../../Icon/Icon';

interface NavAccordionProps {
	children?: ReactNode;
	menuExpanded: boolean;
}

export const NavAccordion: FC<NavAccordionProps> = ({
	children,
	menuExpanded,
}) => {
	const showIconOnly = menuExpanded ? 'initial' : 'none';
	return (
		<Accordion
			allowMultiple={true}
			width={menuExpanded ? '100%' : 'auto'}
			transition="width .3s">
			<AccordionItem border="none" borderRadius="8px">
				{({ isExpanded }) => (
					<>
						<h2>
							<AccordionButton
								borderRadius={`8px 8px ${
									isExpanded ? '0px 0px' : '8px 8px'
								}`}
								px={menuExpanded ? '10px' : '16px'}
								py={4}
								_hover={{ bg: 'blueText' }}
								bg={isExpanded ? 'blueText' : 'lightBlue'}
								justifyContent="center"
								isTruncated={true}
								gap="10px"
								alignItems="center">
								<Box boxSize="24px">
									<Icon
										name="community"
										width="24px"
										height="24px"
										color="white"
									/>
								</Box>
								<Flex
									display={showIconOnly}
									flex={1}
									textAlign="start"
									alignItems="center"
									transition=".3s"
									isTruncated={true}>
									<Text
										fontSize="16px"
										fontWeight="600"
										whiteSpace="nowrap"
										color="white">
										Community
									</Text>
								</Flex>
								<AccordionIcon
									display={showIconOnly}
									bg="transparent"
									color="white"
									boxSize={7}
									ml="auto"
									w="24px"
									h="24px"
								/>
							</AccordionButton>
						</h2>
						<AccordionPanel
							borderRadius="0px 0px 8px 8px"
							bg="blueText"
							px={menuExpanded ? '10px' : 0}
							pb="10px"
							pt="0px">
							<Flex gap="3px" flexDirection="column">
								{children}
							</Flex>
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</Accordion>
	);
};
