export const colors = {
	blueText: '#0F1B49',
	lightBlue: '#1C2959',
	darkBlue: '#10162C',
	lightGray: '#7E788F',
	lightGreen: '#08BB4B',
	background: '#F1F4F9',
	inactiveBlue: '#4A5991',
	lightText: '#7E788F',
	lightBlueButton: {
		500: '#1C2959',
		600: '#10162C',
	},
	darkButton: {
		500: '#10162C',
		600: '#060917',
	},
	greenButton: {
		500: '#08BB4B',
		600: '#007a30',
	},
	inactiveButton: {
		500: '#7E788F',
		600: '#6e687d',
	},
	dropBlue: '#BDCDE9',
	darkBlueTable: {
		100: '#0F1B49',
	},
	softBlue: {
		100: '#D8E2F3',
	},
	whiteButton: {
		500: '#ffffff',
		600: '#e5e5e5',
	},
	lightGray100: '#DBDBDB',
	lightGray200: '#8E8E8E',
	dashboardYellow: '#ECCE2E',
	dashboardRed: '#F35050',
};
