import React, { Dispatch, SetStateAction } from 'react';
import { Flex, Icon, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';
import { toolTip } from '../../../../../theme/elementStyles';
import { IUser, GuestUser } from '../../../types';

interface ReinviteUserEntityProps {
	id: number;
	email: string;
	setUsersDataForDeletion: Dispatch<SetStateAction<(IUser | GuestUser)[]>>;
	setUserIdsForDeletion: Dispatch<SetStateAction<number[]>>;
}

export const ReinviteUserEntity: React.FC<ReinviteUserEntityProps> = ({
	id,
	email,
	setUsersDataForDeletion,
	setUserIdsForDeletion,
}) => {
	const deleteUserFromList = (userId: number) => {
		setUsersDataForDeletion(prev =>
			prev.filter(user => user.id !== userId),
		);
		setUserIdsForDeletion(prev => prev.filter(id => id !== userId));
	};
	return (
		<Flex alignItems={'center'}>
			<Text fontSize={'14px'} fontWeight={'700'} color={'darkBlue'}>
				{email}
			</Text>
			<Tooltip
				{...toolTip}
				placement={'top-start'}
				top={'5px'}
				right={'-25px'}
				label={'Remove user from list'}>
				<IconButton
					_hover={{ bg: 'inherit' }}
					aria-label={'remove user'}
					onClick={() => deleteUserFromList(id)}
					icon={
						<Icon as={IoMdClose} color={'red'} boxSize={'23px'} />
					}
				/>
			</Tooltip>
		</Flex>
	);
};
