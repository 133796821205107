import { FC, useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Text,
} from '@chakra-ui/react';
import { colors } from '../../../../theme/colors';

interface ResetUser2faAuthStateDialog {
	isOpen: boolean;
	onClose: VoidFunction;
	confirmCb: VoidFunction;
	isLoading: boolean;
}

export const ResetUser2faAuthState: FC<ResetUser2faAuthStateDialog> = ({
	isOpen,
	onClose,
	isLoading,
	confirmCb,
}) => {
	const cancelRef = useRef(null);
	return (
		<AlertDialog
			isOpen={isOpen}
			onClose={onClose}
			leastDestructiveRef={cancelRef}>
			<AlertDialogOverlay>
				<AlertDialogContent minW={'505px'}>
					<AlertDialogHeader p={'20px'}>
						<Text
							fontSize={'18px'}
							fontWeight={'600'}
							color={'lightBlueButton.600'}>
							Reset authentication
						</Text>
					</AlertDialogHeader>

					<AlertDialogBody p={'0 20px 20px 20px'}>
						<Text color={'lightBlueButton.600'}>
							Are you sure? You will not be able to cancel this
							action later.
						</Text>
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button
							onClick={onClose}
							border={`2px solid ${colors.greenButton['500']}`}
							h={'40px'}
							bg={'white'}
							color={'lightBlueButton.500'}>
							Cancel
						</Button>
						<Button
							bg={'greenButton.500'}
							onClick={confirmCb}
							isLoading={isLoading}
							color={'white'}
							ml={'10px'}>
							Reset
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
