import React, { useEffect, useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { colors } from '../../../theme/colors';
import { SetVerificationStatusModal } from './modals/SetVerificationStatus';
import { Header, UsersVerificationTable } from './components';
import {
	useUpdateChannelsBanStatus,
	useChangeVerificationStatus,
	useGetVerificationUsers,
} from './hooks';
import { VerificationUser } from './types';
import isEqual from 'lodash/isEqual';
import { useDebounce } from '../../../hooks/useDebounce';

const Verification: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [searchTerm, setSearchTerm] = useState('');
	const [sortOrder, setSortOrder] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedUsers, setSelectedUsers] = useState<VerificationUser[]>([]);
	const [searchByStatus, setSearchByStatus] = useState('1');

	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	const { refetch, isLoading, data } = useGetVerificationUsers(
		sortOrder,
		pageSize,
		currentPage,
		searchByStatus,
		debouncedSearchTerm,
	);
	const { isLoading: updateStatusLoading, onChangeVerificationStatus } =
		useChangeVerificationStatus(refetch, clearUsersSelection);
	const { isLoading: updateChannelBanLoading, onUpdateChannelsBanStatus } =
		useUpdateChannelsBanStatus(refetch);

	const { users, totalCount } = data || {};
	const isAllSelected =
		!!(users?.length && selectedUsers?.length) &&
		isEqual(
			users.map(i => i.id),
			selectedUsers.map(i => i.id),
		);
	const isUsersSelected = selectedUsers.length;

	const addUserToSelected = (user: VerificationUser) =>
		setSelectedUsers(prevState => [...prevState, user]);
	const removeUserFromSelected = (user: VerificationUser) =>
		setSelectedUsers(prevState =>
			prevState.filter(prevUser => user.id !== prevUser.id),
		);

	function clearUsersSelection() {
		setSelectedUsers([]);
	}
	const selectAllHandler = () => {
		if (isAllSelected) {
			setSelectedUsers([]);
		} else {
			users?.length && setSelectedUsers(users);
		}
	};

	useEffect(() => {
		!isUsersSelected && onClose();
	}, [isUsersSelected]);

	useEffect(() => {
		setSelectedUsers([]);
	}, [pageSize, currentPage]);

	useEffect(() => {
		setCurrentPage(1);
	}, [sortOrder, searchByStatus]);

	return (
		<>
			<Box bg={colors.background}>
				<Header
					onOpen={onOpen}
					sortOrder={sortOrder}
					setSortOrder={setSortOrder}
					showPopUpButtonDisabled={!isUsersSelected}
					setSearchByStatus={setSearchByStatus}
					setSearchTerm={setSearchTerm}
					searchTerm={searchTerm}
				/>
				<UsersVerificationTable
					selectAllHandler={selectAllHandler}
					isAllSelected={isAllSelected}
					selectedUsers={selectedUsers}
					addUserToSelected={addUserToSelected}
					removeUserFromSelected={removeUserFromSelected}
					isLoading={isLoading}
					users={users}
					totalCount={totalCount}
					setPageSize={setPageSize}
					setCurrentPage={setCurrentPage}
					pageSize={pageSize}
					currentPage={currentPage}
					onChangeVerificationStatus={onChangeVerificationStatus}
					updateStatusLoading={updateStatusLoading}
					onUpdateChannelsBanStatus={onUpdateChannelsBanStatus}
					updateChannelBanLoading={updateChannelBanLoading}
				/>
			</Box>
			<SetVerificationStatusModal
				removeUserFromSelected={removeUserFromSelected}
				selectedUsers={selectedUsers}
				onChangeVerificationStatus={onChangeVerificationStatus}
				updateStatusLoading={updateStatusLoading}
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
			/>
		</>
	);
};

export default Verification;
