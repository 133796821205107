import { useMutation, useQueryClient } from 'react-query';
import { API_IDENTITY } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils/Alerter';

export const useChangeGuestPermission = () => {
	const queryClient = useQueryClient();
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ userId: number; permissionId: number; isNotified: boolean }
	>({
		mutationFn: payload =>
			API_IDENTITY.post('/api/admin/guest-users/update', {
				...payload,
				roleId: 2,
			}),
		onSuccess: (data, { userId }) => {
			if (data.success) {
				queryClient.invalidateQueries(['user-by-id', userId]);
			} else {
				Alerter.error(data.errors?.[0]?.message);
			}
		},
		onError: () => {
			Alerter.error('Error while changing role');
		},
	});
};
