import { useState, Dispatch, SetStateAction } from 'react';
import { reinviteUsersReq } from '../helper';
import { IUser, GuestUser } from '../types';

interface HookProps {
	setUsersDataForDeletion: Dispatch<SetStateAction<(IUser | GuestUser)[]>>;
	setUserIdsForDeletion: Dispatch<SetStateAction<number[]>>;
}

export const useReinviteMultipleUsers = ({
	setUsersDataForDeletion,
	setUserIdsForDeletion,
}: HookProps) => {
	const [isLoading, setIsLoading] = useState(false);

	const reinviteUsers = async (
		users: (IUser | GuestUser)[],
		cb: VoidFunction,
	) => {
		if (!users.length) {
			setIsLoading(false);
			return;
		}

		setIsLoading(true);

		const userIdsArr = users.map(user => user.id);

		const response = await reinviteUsersReq(userIdsArr);

		if (response) {
			setUserIdsForDeletion([]);
			setUsersDataForDeletion([]);
			setIsLoading(false);
			cb();
		}
	};

	return {
		reinviteUsers,
		isLoading,
	};
};
