import React, { useState } from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import {
	FactorCard,
	Gauges,
	UnavailablePlaceholder,
	ActionResponse,
	ActionResponsesHeader,
} from './components';
import { ActionResponseEmailModal } from './modals';
import { useGetUserRiskScore } from '../../hooks';
import { RiskScoreAction } from '../../types';

interface IRiskScoreProps {
	userId: number;
}

export const RiskScore: React.FC<IRiskScoreProps> = ({ userId }) => {
	const { data } = useGetUserRiskScore(userId);
	const [selectedActionView, setSelectedActionView] =
		useState<RiskScoreAction | null>(null);

	const onCloseActionResponseViewModal = () => {
		setSelectedActionView(null);
	};

	if (data?.value === null) {
		return (
			<Box borderRadius="10px" padding="20px" bg="white">
				<UnavailablePlaceholder>
					Risk Score is unavailable for the current user
				</UnavailablePlaceholder>
			</Box>
		);
	}

	return (
		<Box borderRadius="10px" padding="20px" bg="white">
			<Gauges data={data?.value} />
			<Text color="black" fontSize="18px" fontWeight={600} mt="24px">
				Factors:
			</Text>
			<SimpleGrid columns={2} mt="16px" gap="8px">
				<FactorCard
					name="Less than $10k on the onboarding form"
					value={25}
					state={data?.value?.onboardingFormAction}
					triggerDate={
						data?.value?.onboardingFormTriggerDateTime || null
					}
					closingDate={
						data?.value?.onboardingFormClosingDateTime || null
					}
				/>
				<FactorCard
					name="No PandaDoc after 24 hours of enrollment"
					value={75}
					state={data?.value?.pandaEnrollmentAction}
					triggerDate={
						data?.value?.pandaEnrollmentTriggerDateTime || null
					}
					closingDate={
						data?.value?.pandaEnrollmentClosingDateTime || null
					}
				/>
				<FactorCard
					name="Did not complete 82% of course after 7 days"
					value={50}
					state={data?.value?.courseCompletionAction}
					triggerDate={
						data?.value?.courseCompletionTriggerDateTime || null
					}
					closingDate={
						data?.value?.courseCompletionClosingDateTime || null
					}
				/>
				<FactorCard
					name="No Response to DM after 24 hours"
					value={50}
					state={data?.value?.dmResponseAction}
					triggerDate={data?.value?.dmResponseTriggerDateTime || null}
					closingDate={data?.value?.dmResponseClosingDateTime || null}
				/>
				<FactorCard
					name="No login to Section 8 Pro after 24 hours"
					value={75}
					state={data?.value?.platformLoginAction}
					triggerDate={
						data?.value?.platformLoginTriggerDateTime || null
					}
					closingDate={
						data?.value?.platformLoginClosingDateTime || null
					}
				/>
				<FactorCard
					name="No Enrollment into Community after 18 hours"
					value={75}
					state={data?.value?.communityEnrollmentAction}
					triggerDate={
						data?.value?.communityEnrollmentTriggerDateTime || null
					}
					closingDate={
						data?.value?.communityEnrollmentClosingDateTime || null
					}
				/>
			</SimpleGrid>
			<ActionResponsesHeader
				userId={userId}
				isMailingDisabled={data?.value?.isMailingDisabled}
			/>
			{data?.value?.actions?.length ? (
				<SimpleGrid columns={2} mt="16px" gap="8px">
					{data?.value?.actions.map((elem, index) => (
						<ActionResponse
							key={index}
							{...elem}
							onViewMore={() => {
								setSelectedActionView(elem);
							}}
						/>
					))}
				</SimpleGrid>
			) : (
				<UnavailablePlaceholder containerProps={{ mt: '16px' }}>
					No action-responses have been issued yet
				</UnavailablePlaceholder>
			)}
			<ActionResponseEmailModal
				isOpen={!!selectedActionView}
				onClose={onCloseActionResponseViewModal}
				data={selectedActionView}
			/>
		</Box>
	);
};
