interface LinkItemProps {
	id: number;
	iconName: string;
	text: string;
	link: string;
	innerLinks?: LinkItemProps[];
}

export const LinkItems: Array<LinkItemProps> = [
	{
		id: 1,
		text: 'Users',
		iconName: 'users',
		link: '/',
	},
	{
		id: 2,
		text: 'Courses',
		iconName: 'graduation-hat',
		link: '/courses',
	},
	{
		id: 5,
		text: 'Community',
		iconName: 'community',
		link: '',
		innerLinks: [
			{
				id: 6,
				text: 'Verification',
				iconName: 'verification',
				link: '/community/verification',
			},
		],
	},
	{
		id: 3,
		text: 'Logs',
		iconName: 'logs',
		link: '/logs',
	},
	{
		id: 4,
		text: 'Permission Groups',
		iconName: 'file-lock',
		link: '/permission-groups',
	},
	{
		id: 5,
		text: 'Transaction History',
		iconName: 'credit-card-search',
		link: '/transaction-history',
	},
];
